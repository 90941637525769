import React, {Component, Fragment} from "react";
import {v4 as uuidv4} from 'uuid';
import {connect} from "react-redux";
import {useTranslation, withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {useDrag, useDrop} from "react-dnd";
import {Alert, Avatar, Box, Button, Chip, Divider, Fab, IconButton, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EmailIcon from '@mui/icons-material/Email';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from '@fortawesome/free-solid-svg-icons'
import EditorAddUserDialog from "./EditorAddUserDialog";
import EditorAddSignerGroupDialog from "./EditorAddSignerGroupDialog";
import EditorBulkSignatureTypesDialog from "./EditorBulkSignatureTypesDialog";
import {FORM_FIELD_TYPES, SIGNATURE_TYPES} from "../common/Constants";
import {SIGNER_KIOSK_MULTIPLE_REGISTERED_USERS_ERROR, SIGNER_NO_PERSON_ERROR, SIGNER_NO_SIGNATURE_TYPE, SIGNER_SMS_OTP_ERROR, SIGNERS_ERROR_COUNT} from "./EditorUtils";
import {FieldsContainer, FormFieldsContainer, SignerSettingsComponent} from "./EditorCommonComponents";
import SplitButton from "../common/SplitButton";
import {EditorEmailMessageDialog} from "./EditorEmailMessageDialog";
import {EditorSigningOrderGroupSettingsDialog} from "./EditorSigningOrderGroupSettingsDialog";
import UserImportLookupDialog from "../user/UserImportLookupDialog";
import ConfirmationDialog from "../common/ConfirmationDialog";

const BUILD_ORDER_GROUPS = (signers) => {
	let pos = 0;
	return (signers || [])
		.reduce((acc, signer) => {
			let group = acc.find(search => search.order === signer.order);
			if (!group) {
				group = {signers: [], order: signer.order, locked: false};
				acc.push(group);
			}
			group.locked |= signer.signed;
			group.signers.push({...signer, pos: ++pos});
			return acc;
		}, [])
		.sort((a, b) => a.order - b.order);
}

const DraggableSigningFieldWrapper = ({signer, onFieldDropped, sx, children}) => {
	const [, drag] = useDrag(() => ({
		type: 'signer',
		item: signer,
		end: (signer, monitor) => {
			const dropResult = monitor.getDropResult()
			if (signer && dropResult) {
				const {pageIndex, rect} = dropResult;
				onFieldDropped({signer, pageIndex, rect});
			}
		},
	}))
	return <Box ref={drag} sx={{border: '1px dashed #76bde9', cursor: 'grab', ...(!!sx && sx), minWidth: 0}}>
		{children}
	</Box>
}

const DraggableOrderGroupWrapper = ({group, onOrderGroupDropped, children}) => {
	const {t} = useTranslation();
	const [{isDragging}, drag, preview] = useDrag(() => ({
		type: 'group',
		item: group,
		end: (group, monitor) => {
			const dropResult = monitor.getDropResult()
			if (group && dropResult) {
				onOrderGroupDropped(group, dropResult.order, dropResult.orderOutside);
			}
		}
	}), [group]);
	const onlyOneSigner = group.signers.length === 1;
	return <Box ref={preview} sx={{display: 'flex', alignItems: onlyOneSigner ? 'center' : 'flex-start'}}>
		<IconButton ref={drag} title={t('editor.signingOrderGroupDrag')} sx={{cursor: 'grab'}}>
			<DragIndicatorIcon fontSize="small"/>
		</IconButton>
		<Box sx={{flexGrow: 1, minWidth: 0}}>
			{children}
		</Box>
	</Box>
}

const DroppableOrderGroupWrapper = ({order, orderOutside, ruler, sx, dropSx, children, locked}) => {
	const [{canDrop, isOver}, drop] = useDrop(() => ({
		accept: locked ? 'none' : 'group',
		drop: (item, monitor) => ({
			order,
			orderOutside
		}),
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}), [order, orderOutside]);

	const isActive = canDrop && isOver;
	if (ruler) {
		return <Box ref={drop} sx={{zIndex: 1, height: '30px', mt: '-10px', mb: '-10px'}}>
			{isActive &&
				<Box sx={{position: 'relative', top: '15px', height: '0px', borderBottom: '1px dashed #76bde9'}}/>}
		</Box>
	} else {
		return <Box ref={drop} sx={{...(isActive ? dropSx : sx)}}>
			{children}
		</Box>
	}
}

class EditorSignersComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			signerAddDialogOpen: false,
			signerGroupAddDialogOpen: false,
			signerImportDialogOpen: false,
			bulkSignatureTypesDialogOpen: false,
			selectedSignerIdForSettings: '',
			selectedSignerIdForChooseSigner: '',
			selectedGroupForGroupSettings: null,
			emailMessageDialogOpen: false,
			signingKioskChangesDialogOpen: false,
		}
	}

	render() {
		const document = this.props.document;
		const readOnly = document.readOnly;
		const signers = document?.signers || [];
		const isTemplate = document.state === 'TEMPLATE';
		const kioskMode = document.signingOrderType === 'KIOSK'
		const kioskModeEnabledForFolder = this.props.editorEditInfo.folders.find(folder => folder.id === this.props.folderId).kioskSigningOrderTypeEnabled;
		let availableSigningOrderTypes = ['PARALLEL', 'SERIAL']
		if (kioskModeEnabledForFolder) {
			availableSigningOrderTypes.push('KIOSK');
		}
		const orderGroups = BUILD_ORDER_GROUPS(document?.signers);

		return <Box sx={{mt: 2}}>
			<Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
				<Box sx={{flexGrow: 1}}>
					<Typography sx={{fontWeight: 700}}>{this.props.t('editor.signingList')}</Typography>
				</Box>
				<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
					<ToggleButtonGroup
						color="primary"
						value={document.signingOrderType || ''}
						exclusive
						size="small"
						onChange={this.onChangeSigningOrderType}
						disabled={readOnly}
					>
						{availableSigningOrderTypes.map(order =>
							<ToggleButton key={order} value={order}>
								{this.props.t('editor.signingOrder_' + order)}
							</ToggleButton>
						)}
					</ToggleButtonGroup>
					<SplitButton color="secondary" actions={[
						{
							title: this.props.t('editor.signingAddSigner'),
							action: this.onOpenSignerDialog,
							disabled: readOnly,
							icon: <AddIcon/>,
							id: "btn-editor-signers-add"
						},
						{
							title: this.props.t('editor.signingAddSignerGroup'),
							action: this.onOpenSignerGroupDialog,
							disabled: readOnly,
							icon: <AddIcon/>,
							id: "btn-editor-signergroups-add"
						},
						...(this.props.editorEditInfo.canImportUsers ? [{
							title: this.props.t('editor.signingImportSigners'),
							action: this.onOpenImportDialog,
							disabled: readOnly,
							icon: <FontAwesomeIcon icon={faFileCsv}/>,
							id: "btn-editor-signers-import"
						}] : []),
						...(isTemplate ? [{
							title: this.props.t('editor.signingPlaceholderAdd'),
							action: this.onSignerAddPlaceholder,
							disabled: readOnly,
							icon: <PersonSearchIcon/>,
							id: "btn-editor-signers-template-add"
						}] : [])
					]}/>
					<Fab size="small"
						 color="secondary"
						 title={this.props.t('editor.signingBulkSignatureTypes')}
						 onClick={this.onOpenBulkSignatureTypesDialog}
						 disabled={readOnly || signers.length === 0}
					>
						<SettingsSuggestIcon fontSize="small"/>
					</Fab>
					{!kioskMode && <Fab size="small"
										color="secondary"
										title={this.props.t('editor.emailMessageHeaderSigners')}
										onClick={this.onOpenEmailMessageDialog}
										disabled={readOnly || signers.length === 0}
					>
						<EmailIcon fontSize="small"/>
					</Fab>}
				</Box>
			</Box>

			{signers.length === 0 &&
				<Alert severity="info" sx={{mt: 1}}>{this.props.t('editor.signingListEmpty')}</Alert>}

			<Box sx={{mt: 1, display: 'flex', flexDirection: 'column', flexShrink: 0}}>
				{orderGroups.map((group, groupIndex) => {
					const innerContent = <Box>
						{group.signers.length > 1 && <Box
							sx={{
								bgcolor: 'white',
								mt: -2,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: 'fit-content',
								pl: 0.5,
								pr: 0.5,
								zIndex: 10,
								position: 'relative'
							}}
						>
							{document.signingOrderType === 'SERIAL' &&
								<Chip size="small" label={<Typography variant="body2">{group.order}</Typography>}
									  sx={{mr: 0.5}}/>}
							<IconButton
								size="small"
								title={this.props.t('editor.signingOrderGroupDisband')}
								disabled={readOnly || group.locked}
								onClick={() => this.onSigningOrderGroupDisband(group)}
							>
								<LinkOffIcon fontSize="small"/>
							</IconButton>
							<IconButton
								size="small"
								title={this.props.t('editor.signingOrderGroupSettings')}
								onClick={() => this.onSigningOrderGroupOpenSettingsDialog(group)}
							>
								<SettingsIcon fontSize="small"/>
							</IconButton>
						</Box>}
						{group.signers.map((signer, signerIndex) => {
							const signerSignatureTypeError = SIGNER_NO_SIGNATURE_TYPE(signer);
							const signerSmsOtpError = SIGNER_SMS_OTP_ERROR(document, signer);
							const signerNoPersonError = SIGNER_NO_PERSON_ERROR(document, signer);
							const signerKioskMultipleRegisteredUsersError = SIGNER_KIOSK_MULTIPLE_REGISTERED_USERS_ERROR(document);
							const showSignatureSettings =
								(signer.id === this.state.selectedSignerIdForSettings) ||
								(signer.paraphFields || []).length > 0 ||
								(signer.extraSignatureFields || []).length > 0 ||
								(signer.formFields || []).length > 0
							;

							const smallSignaturesComponent = <Box>
								{Object.keys(SIGNATURE_TYPES).map(type =>
									(signer.signatureTypes.indexOf(type) >= 0 ?
										<img key={type} src={SIGNATURE_TYPES[type].img}
											 style={{marginLeft: '2px', marginRight: '2px', width: '19px', height: 'auto', verticalAlign: 'middle'}}/>
										: null))}
							</Box>;
							const details = <Box sx={{flexGrow: 1, ml: 1, flexWrap: 1, minWidth: 0}}>
								{!!signer.person && <>
									<Box sx={{display: 'flex', gap: 0.5}}>
										<Box sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1}} title={signer.person.firstName + ' ' + signer.person.lastName}>{signer.person.firstName + ' ' + signer.person.lastName}</Box>
										{!showSignatureSettings && smallSignaturesComponent}
									</Box>
									<Typography variant="body2" sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{signer.person.email}</Typography>
								</>}
								{!signer.person && <>
									<Typography variant="body2" color={signerNoPersonError ? 'error' : 'primary'}>
										{this.props.t('editor.signingPlaceholder') + ' ' + signer.pos}
										{!showSignatureSettings && smallSignaturesComponent}
									</Typography>
								</>}
								{!!signer.capacityTitle && <Chip
									label={<Typography variant="body2">{signer.capacityTitle}</Typography>}
									size="small"/>}
							</Box>;

							return <Box key={signer.id} sx={{pr: 0.5}}>
								<Box sx={{display: 'flex', alignItems: 'center', p: 1, gap: 1}}>
									<Avatar sx={{bgcolor: 'lightgrey', mr: -1}}>
										{(document.signingOrderType === 'SERIAL' && group.signers.length === 1) ?
											(signer.order) :
											<PersonIcon fontSize="large"/>
										}
									</Avatar>

									{(!signer.signatureField && !readOnly) ?
										<DraggableSigningFieldWrapper
											key={signer.id}
											signer={signer}
											onFieldDropped={this.props.onSignerDropped}
											sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}
										>
											<ZoomOutMapIcon fontSize="large"/>
											{details}
										</DraggableSigningFieldWrapper>
										:
										details
									}

									{!isTemplate &&
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center'
											}}
										>
											<Chip
												label={<Typography variant="body2">
													{document.state === 'ATTACHMENT' ? this.props.t('signing.attachment') : this.props.t('signing.signRequestState_' + (signer.signRequestState || 'NEW'))}
												</Typography>}
												size="small"/>
										</Box>
									}

									<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
										{!!signer.signatureField &&
											<Typography variant="body2">
												{this.props.t('editor.signingPage') + ': ' + (signer.signatureField.pageIndex + 1)}
											</Typography>
										}
										{signerSignatureTypeError &&
											<Typography
												color="error"
												variant="body2"
											>
												{this.props.t('editor.signingSignatureTypeError')}
											</Typography>
										}
										{signerSmsOtpError && !showSignatureSettings &&
											<Typography
												color="error"
												variant="body2"
											>
												{this.props.t('editor.signingSmsOtpNumberError')}
											</Typography>
										}
									</Box>

									<Divider orientation="vertical" flexItem/>

									<Box sx={{display: 'flex'}}>
										<IconButton
											variant="contained"
											size="small"
											color={signerNoPersonError ? 'error' : 'primary'}
											title={this.props.t('editor.signingChooseSigner')}
											onClick={() => this.onOpenChooseSignerSetDialog(signer)}
											disabled={readOnly || signer.signed}
											id="btn-editor-choose-signer"
										>
											<PersonSearchIcon/>
										</IconButton>
										<IconButton
											size="small"
											color={signerSmsOtpError ? 'error' : 'primary'}
											title={this.props.t('editor.signingSignatureSettings')}
											onClick={() => this.onChangeSelectedSignerIdForSettings(signer.id)}
											disabled={(readOnly && !document?.canUpdateSigningMethods) || !document?.editPermission}
											id="btn-editor-signer-settings"
										>
											<SettingsIcon/>
										</IconButton>
										<IconButton
											size="small"
											color="primary"
											title={this.props.t('editor.signingDelete')}
											onClick={() => this.onSignerDelete(signer.id)}
											disabled={readOnly || signer.signed}
											id="btn-editor-signer-delete"
										>
											<DeleteIcon/>
										</IconButton>
									</Box>
								</Box>
								{showSignatureSettings &&
									<Box sx={{ml: '28px', pl: '34px', borderLeft: '1px dashed lightgrey', mb: 1}}>

										<SignerSettingsComponent
											document={document}
											signer={signer}
											readOnly={readOnly}
											onSignerChangeSignatureType={this.onSignerChangeSignatureType}
											onSignerChangeCapacityTitle={this.onSignerChangeCapacityTitle}
											onSignerChangeUseKnownOtpNumber={this.onSignerChangeUseKnownOtpNumber}
											onSignerChangeOtpNumber={this.onSignerChangeOtpNumber}
											onSignerChangeGuestExpiryDuration={this.onSignerChangeGuestExpiryDuration}
										/>

										{(!readOnly || signer?.paraphFields?.length > 0) &&
											<Box
												sx={{display: 'flex', alignItems: 'center', gap: 1, minHeight: '52px'}}>
												{!readOnly && <DraggableSigningFieldWrapper
													key={signer.id}
													signer={signer}
													onFieldDropped={this.props.onParaphFieldDropped}
													sx={{display: 'flex', alignItems: 'center', flexShrink: 0}}
												>
													<ZoomOutMapIcon fontSize="large"/>
												</DraggableSigningFieldWrapper>}

												<Typography variant="body2" sx={{flexShrink: 0}}>
													{this.props.t('editor.signingParaphField')}
												</Typography>

												<Box sx={{flexGrow: 1}}>
													<FieldsContainer
														readOnly={readOnly}
														signer={signer}
														fields={signer?.paraphFields}
														onFieldRemove={this.props.onParaphFieldRemove}
														onFieldRemoveAll={signer => this.onSignerRemoveAllParaphFields(signer.id)}
													/>
												</Box>
											</Box>
										}

										{(!readOnly || (!!signer?.extraSignatureFields?.length > 0)) &&
											<Box
												sx={{display: 'flex', alignItems: 'center', gap: 1, minHeight: '52px'}}>

												{!readOnly && <DraggableSigningFieldWrapper
													key={signer.id}
													signer={signer}
													onFieldDropped={this.props.onExtraSignFieldDropped}
													sx={{display: 'flex', alignItems: 'center', flexShrink: 0}}
												>
													<ZoomOutMapIcon fontSize="large"/>
												</DraggableSigningFieldWrapper>}

												<Typography variant="body2" sx={{flexShrink: 0}}>
													{this.props.t('editor.signingExtraSignatureField')}
												</Typography>

												<Box sx={{flexGrow: 1}}>
													<FieldsContainer
														readOnly={readOnly}
														signer={signer}
														fields={signer?.extraSignatureFields}
														onFieldRemove={this.props.onExtraSignFieldRemove}
														onFieldRemoveAll={signer => this.onSignerRemoveAllExtraSignFields(signer.id)}
													/>
												</Box>
											</Box>}

										{(!readOnly || (!!signer?.formFields?.length > 0)) && <>
											{FORM_FIELD_TYPES.map(type =>
												<Box
													key={type}
													sx={{
														display: 'flex',
														alignItems: 'center',
														gap: 1,
														minHeight: '52px'
													}}
												>
													{!readOnly && <DraggableSigningFieldWrapper
														key={signer.id}
														signer={signer}
														onFieldDropped={({
																			 signer,
																			 pageIndex,
																			 rect
																		 }) => this.props.onFormFieldDropped({
															signer,
															pageIndex,
															rect,
															type
														})}
														sx={{display: 'flex', alignItems: 'center', flexShrink: 0}}
													>
														<ZoomOutMapIcon fontSize="large"/>
													</DraggableSigningFieldWrapper>}

													<Typography variant="body2" sx={{flexShrink: 0}}>
														{this.props.t('editor.formFieldType_' + type)}
													</Typography>

													<Box sx={{flexGrow: 1}}>
														<FormFieldsContainer
															type={type}
															readOnly={readOnly}
															signer={signer}
															onFormFieldRemove={this.props.onFormFieldRemove}
															onFormFieldRemoveAll={(signer, type) => this.onSignerRemoveAllFormFields(signer.id, type)}
														/>
													</Box>
												</Box>
											)}
										</>}

										{this.props.child && !readOnly && <Box>
											<Button variant="contained"
													onClick={() => this.props.onDistributeSignerToCollection(signer)}
													color="secondary"
													size="small"
													id="btn-editor-signer-distribute-collection"
											>
												{this.props.t('editor.signingDistributeSignerInCollection')}
											</Button>
										</Box>}
									</Box>}
							</Box>
						})}
					</Box>

					return <Fragment key={groupIndex}>
						{/* position before first group */}
						{(0 === groupIndex) &&
							<DroppableOrderGroupWrapper orderOutside={group.order} ruler locked={group.locked}/>}
						{/* group itself */}
						<DroppableOrderGroupWrapper
							order={group.order}
							sx={group.signers.length > 1 ? {border: '1px dashed black'} : {}}
							dropSx={{border: '1px dashed #76bde9'}}
							locked={group.locked}
						>
							{(readOnly || kioskMode)  ? innerContent : <DraggableOrderGroupWrapper group={group}
																				   onOrderGroupDropped={this.onSigningOrderGroupDropped}>
								{innerContent}
							</DraggableOrderGroupWrapper>}
						</DroppableOrderGroupWrapper>
						{/* position after each group */}
						{<DroppableOrderGroupWrapper orderOutside={group.order + 1} ruler/>}
					</Fragment>
				})}

				{!readOnly && SIGNERS_ERROR_COUNT(this.props.document, true) > 0 &&
					<Alert severity="warning" sx={{mt: 2}}>
						{this.props.t('editor.signingNotPlaced')}
					</Alert>}

				{!readOnly && SIGNER_KIOSK_MULTIPLE_REGISTERED_USERS_ERROR(this.props.document) &&
					<Alert severity="warning" sx={{mt: 2}}>
						{this.props.t('editor.signingKioskModeRegisteredUserError')}
					</Alert>}
			</Box>

			<EditorAddUserDialog
				singleSigner
				documentId={document.id}
				folderId={document.folderId}
				title={this.props.t('editor.signingChooseSigner')}
				open={!!this.state.selectedSignerIdForChooseSigner}
				onClose={this.onCloseChooseSignerSetDialog}
				onSelect={this.onSelectUserForChooseSigner}
				kioskMode={this.props.document?.signingOrderType === 'KIOSK'}
			/>

			<EditorAddUserDialog
				title={this.props.t('editor.signingAddSigner')}
				documentId={document.id}
				folderId={document.folderId}
				open={this.state.signerAddDialogOpen}
				onClose={this.onCloseSignerDialog}
				onSelect={this.onSelectUsers}
				kioskMode={this.props.document?.signingOrderType === 'KIOSK'}
			/>

			<EditorAddSignerGroupDialog
				title={this.props.t('editor.signingAddSignerGroup')}
				folderId={document.folderId}
				open={this.state.signerGroupAddDialogOpen}
				onClose={this.onCloseSignerGroupDialog}
				onSelect={this.onSelectUsers}
			/>

			<UserImportLookupDialog
				title={this.props.t('editor.signingImportSigners')}
				open={this.state.signerImportDialogOpen}
				onClose={this.onCloseImportDialog}
				onSelect={this.onSelectUsers}
				canInviteUsers={this.props.editorEditInfo?.canInviteGuests || this.props.editorEditInfo?.canInviteRegisteredUsers}
				allowContacts
				folderId={document.folderId}
			/>

			<EditorBulkSignatureTypesDialog
				open={this.state.bulkSignatureTypesDialogOpen}
				onClose={this.onCloseBulkSignatureTypesDialog}
				possibleSignatureTypes={document.enabledSignatureTypes}
				onChangeSignatureTypes={this.onBulkChangeSignatureTypes}
			/>

			<EditorEmailMessageDialog
				open={!!this.state.emailMessageDialogOpen}
				onClose={this.onCloseEmailMessageDialog}
				onUpdate={this.onUpdateEmailMessageDialog}
				forSigning
				sessionInfo={this.props.sessionInfo}
				customEmailHeaders={document.signersCustomEmailHeaders}
			/>

			<EditorSigningOrderGroupSettingsDialog
				open={!!this.state.selectedGroupForGroupSettings}
				onClose={this.onSigningOrderGroupCloseSettingsDialog}
				onConfirm={this.onSigningOrderGroupChangeSettings}
				readOnly={readOnly || !!this.state.selectedGroupForGroupSettings?.locked}
				settings={document?.signingOrderGroupSettings?.find(search => search.signingOrder === this.state.selectedGroupForGroupSettings?.order)}
				signers={this.state.selectedGroupForGroupSettings?.signers}
			/>

			<ConfirmationDialog
				title={this.props.t('editor.signingKioskMode')}
				confirm={this.props.t('editor.signingKioskModeConfirm')}
				open={this.state.signingKioskChangesDialogOpen}
				onClose={this.onSigningKioskChangesDialogClose}
				onConfirm={this.onSigningKioskChangesConfirm}
			/>
		</Box>
	}

	onChangeSigningOrderType = (e) => {
		const signingOrderType = e.target.value;
		const rootDocument = this.props.editorEditInfo.document;
		const showKioskWarning = 'KIOSK' === signingOrderType && (this.requiresKioskOrderTypeWarning(rootDocument) || rootDocument.children.find(child => this.requiresKioskOrderTypeWarning(child)) !== undefined);
		if (showKioskWarning) {
			this.setState({signingKioskChangesDialogOpen: true});
		} else {
			this.props.onChangeSigningOrderType(signingOrderType);
		}
	}

	requiresKioskOrderTypeWarning = (document) => {
		return document.approvers.length > 0 ||
			new Set(document.signers.filter(signer => !signer.person.guest).map(signer => signer.person.id)).size > 1 ||
			!!document.children.find(child => child.childType === 'MULTI_SIGN');
	}

	onSigningKioskChangesConfirm = () => {
		this.setState({signingKioskChangesDialogOpen: false}, () => this.props.onChangeSigningOrderType('KIOSK'));
	}

	onSigningKioskChangesDialogClose = () => {
		this.setState({signingKioskChangesDialogOpen: false});
	}

	onSignerDelete = (id) => {
		// rebuild all groups
		const groups = BUILD_ORDER_GROUPS(this.props.document.signers);
		const signer = this.props.document.signers.find(search => search.id === id);

		// results
		const newSigners = [];
		const newSigningOrderGroupSettings = [];

		let normalizedOrder;
		groups.forEach(group => {
			if (group.order < signer.order) {
				// keep signers & settings
				newSigners.push(...group.signers);
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push(settings);
				}
			} else if (group.order === signer.order) {
				if (1 === group.signers.length) {
					// don't keep signer & settings
					normalizedOrder = group.order;
				} else {
					// keep signers (except the one to delete) & settings
					group.signers.forEach(signer => {
						if (id !== signer.id) {
							newSigners.push(signer);
						}
					});
					const settings = this.props.document.signingOrderGroupSettings
						.find(search => search.signingOrder === group.order);
					if (!!settings) {
						if (settings.minimalNumberOfSigners === group.signers.length) {
							settings.minimalNumberOfSigners--;
						}
						newSigningOrderGroupSettings.push(settings);
					}
					normalizedOrder = group.order + 1;
				}
			} else { // group.order > groupToUnlink.order
				// keep signer & settings but (possibly) relocate them
				group.signers.forEach(signer => {
					signer.order = normalizedOrder;
					newSigners.push(signer);
				});
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push({
						...settings,
						signingOrder: normalizedOrder
					});
				}
				normalizedOrder++;
			}
		});

		this.props.onChangeSigners(newSigners, newSigningOrderGroupSettings);
	}

	onSignerChangeSignatureType = (id, type, checked) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					signatureTypes: (signer?.signatureTypes || [])
						.filter(search => search !== type)
						.concat(checked ? [type] : [])
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerChangeCapacityTitle = (id, capacityTitle) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					capacityTitle
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerChangeUseKnownOtpNumber = (id, value) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					useKnownOtpNumber: value
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerChangeOtpNumber = (id, value) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					useKnownOtpNumber: false,
					otpNumber: value
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerChangeGuestExpiryDuration = (id, value) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					guestExpiryDuration: value
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerRemoveAllParaphFields = (id) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					paraphFields: null
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerRemoveAllExtraSignFields = (id) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					extraSignatureFields: null
				}));

		this.props.onChangeSigners(signers);
	}

	onSignerRemoveAllFormFields = (id, type) => {
		const signers = this.props.document.signers
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					formFields: (signer.formFields || [])
						.filter((formField, ign) => type !== formField.type)
				}));

		this.props.onChangeSigners(signers);
	}

	onSigningOrderGroupDisband = (groupToDisband) => {
		// rebuild all groups
		const groups = BUILD_ORDER_GROUPS(this.props.document.signers);

		// results
		const newSigners = [];
		const newSigningOrderGroupSettings = [];

		let normalizedOrder;
		groups.forEach(group => {
			if (group.order < groupToDisband.order) {
				// no touchy
				newSigners.push(...group.signers);
				// also keep settings
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push(settings);
				}
			} else if (group.order === groupToDisband.order) {
				// disband all signers in group, give them proper order
				normalizedOrder = group.order;
				group.signers.forEach(signer => {
					signer.order = normalizedOrder;
					signer.requiredInOrderGroup = false;
					normalizedOrder++;
					newSigners.push(signer);
				});
				// don't keep settings
			} else { // group.order > groupToUnlink.order
				// use normalizedOrder for each signer
				group.signers.forEach(signer => {
					signer.order = normalizedOrder;
					newSigners.push(signer);
				});
				// relocate settings
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push({
						...settings,
						signingOrder: normalizedOrder
					});
				}
				// increment only at the end
				normalizedOrder++;
			}
		});

		this.props.onChangeSigners(newSigners, newSigningOrderGroupSettings);
	}

	onSigningOrderGroupOpenSettingsDialog = (group) => {
		this.setState({
			selectedGroupForGroupSettings: group
		});
	}

	onSigningOrderGroupCloseSettingsDialog = () => {
		this.setState({
			selectedGroupForGroupSettings: null
		});
	}

	onSigningOrderGroupChangeSettings = (settings) => {
		const newSigners = this.props.document.signers
			.map(signer => signer.order !== this.state.selectedGroupForGroupSettings.order ? signer : {
				...signer,
				requiredInOrderGroup: settings.requiredSigners.indexOf(signer.id) >= 0
			});

		const newSigningOrderGroupSettings = this.props.document.signingOrderGroupSettings
			.filter(search => search.signingOrder !== this.state.selectedGroupForGroupSettings.order);
		newSigningOrderGroupSettings.push({
			signingOrder: this.state.selectedGroupForGroupSettings.order,
			minimalNumberOfSigners: settings.minimalNumberOfSigners
		});

		this.setState({selectedGroupForGroupSettings: null}, () => {
			this.props.onChangeSigners(newSigners, newSigningOrderGroupSettings);
		});
	}

	onSigningOrderGroupDropped = (groupThatWasDropped, order, orderOutside) => {
		// rebuild all groups
		const groups = BUILD_ORDER_GROUPS(this.props.document.signers);

		// results
		const newSigners = [];
		const newSigningOrderGroupSettings = [];

		let normalizedOrder = 1;
		if (undefined !== order && groupThatWasDropped.order !== order) {
			groups.filter(group => group.order !== groupThatWasDropped.order).forEach(group => {
				// use normalizedOrder for each signer of the existing group
				// since the groupThatWasDropped can have any order (including first one)
				group.signers.forEach(signer => {
					signer.order = normalizedOrder;
					newSigners.push(signer);
				});
				// if we found the group target, add the signers from groupThatWasDropped
				// to the group (with the correct normalizedOrder)
				if (group.order === order) {
					groupThatWasDropped.signers.forEach(signer => {
						signer.order = normalizedOrder;
						newSigners.push(signer);
					});
				}
				// relocate settings
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push({
						...settings,
						signingOrder: normalizedOrder
					});
				}
				// increment only at the end
				normalizedOrder++;
			});

			this.props.onChangeSigners(newSigners, newSigningOrderGroupSettings);
		} else if (undefined !== orderOutside) {
			// remove groupThatWasDropped from group list
			const newGroups = groups.filter(group => group.order !== groupThatWasDropped.order);

			// insert groupThatWasDropped on the correct spot
			let currentIndex = 0;
			while (currentIndex < newGroups.length && newGroups[currentIndex].order < orderOutside) {
				currentIndex++;
			}
			newGroups.splice(currentIndex, 0, groupThatWasDropped);

			// normalize group order
			newGroups.forEach(group => {
				// use normalizedOrder for each signer of the existing group
				group.signers.forEach(signer => {
					signer.order = normalizedOrder;
					newSigners.push(signer);
				});
				// relocate settings
				const settings = this.props.document.signingOrderGroupSettings
					.find(search => search.signingOrder === group.order);
				if (!!settings) {
					newSigningOrderGroupSettings.push({
						...settings,
						signingOrder: normalizedOrder
					});
				}
				// increment only at the end
				normalizedOrder++;
			});

			this.props.onChangeSigners(newSigners, newSigningOrderGroupSettings);
		}
	}

	onOpenChooseSignerSetDialog = (signer) => {
		this.setState({selectedSignerIdForChooseSigner: signer.id});
	}

	onCloseChooseSignerSetDialog = () => {
		this.setState({selectedSignerIdForChooseSigner: ''});
	}

	onChangeSelectedSignerIdForSettings = (selectedSignerIdForSettings) => {
		if (selectedSignerIdForSettings === this.state.selectedSignerIdForSettings) {
			this.setState({selectedSignerIdForSettings: ''})
		} else {
			this.setState({selectedSignerIdForSettings});
		}
	}

	onOpenSignerDialog = () => {
		this.setState({signerAddDialogOpen: true});
	}

	onCloseSignerDialog = () => {
		this.setState({signerAddDialogOpen: false});
	}

	onOpenSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: true});
	}

	onCloseSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: false});
	}

	onOpenImportDialog = () => {
		this.setState({signerImportDialogOpen: true});
	}

	onCloseImportDialog = () => {
		this.setState({signerImportDialogOpen: false});
	}

	onSignerAddPlaceholder = () => {
		const signers = this.props.document.signers;
		signers.push({
			id: uuidv4(),
			otpNumberKnown: false,
			useKnownOtpNumber: false,
			otpNumberSuggestionSuffix: null,
			signatureTypes: [...this.props.document.enabledSignatureTypes],
			order: signers.reduce((acc, signer) => acc >= signer.order ? acc : signer.order, 0) + 1,
		});
		this.props.onChangeSigners(signers);
	}

	onSelectUserForChooseSigner = (users) => {
		const userToSet = users.at(0);

		const signers = this.props.document.signers
			.map(signer => signer.id === this.state.selectedSignerIdForChooseSigner ? {
				...signer,
				person: {
					id: userToSet.id,
					firstName: userToSet.firstName,
					lastName: userToSet.lastName,
					email: userToSet.email,
					capacityTitles: userToSet.capacityTitles,
					metaFields: userToSet.metaFields
				},
				otpNumberKnown: !!userToSet.otpNumberSuggestionSuffix,
				useKnownOtpNumber: !!userToSet.otpNumberSuggestionSuffix,
				otpNumberSuggestionSuffix: userToSet.otpNumberSuggestionSuffix,
				capacityTitle: null
			} : signer);

		this.setState({selectedSignerIdForChooseSigner: ''}, () => {
			this.props.onChangeSigners(signers);
		});
	}

	onSelectUsers = (usersToAdd, shouldClose) => {
		const signers = this.props.document.signers;

		usersToAdd
			.forEach(userToAdd => {
			signers.push({
				id: uuidv4(),
				person: {
					id: userToAdd.id,
					firstName: userToAdd.firstName,
					lastName: userToAdd.lastName,
					email: userToAdd.email,
					capacityTitles: userToAdd.capacityTitles,
					state: userToAdd.state,
					memo: userToAdd.memo,
					guest: userToAdd.guest,
					metaFields: userToAdd.metaFields
				},
				order: signers.reduce((acc, signer) => acc >= signer.order ? acc : signer.order, 0) + 1,
				otpNumberKnown: !!userToAdd.otpNumberSuggestionSuffix,
				useKnownOtpNumber: !!userToAdd.otpNumberSuggestionSuffix,
				otpNumberSuggestionSuffix: userToAdd.otpNumberSuggestionSuffix,
				signatureTypes: [...this.props.document.enabledSignatureTypes]
			});
		});

		if (shouldClose) {
			this.setState({signerAddDialogOpen: false, signerGroupAddDialogOpen: false, signerImportDialogOpen: false},
				() => this.props.onChangeSigners(signers));
		} else {
			this.props.onChangeSigners(signers);
		}
	}

	onOpenBulkSignatureTypesDialog = () => {
		this.setState({bulkSignatureTypesDialogOpen: true});
	}

	onCloseBulkSignatureTypesDialog = () => {
		this.setState({bulkSignatureTypesDialogOpen: false});
	}

	onBulkChangeSignatureTypes = (signatureTypes) => {
		const signers = this.props.document.signers
			.map(signer => ({
				...signer,
				signatureTypes: [...signatureTypes]
			}));

		this.setState({bulkSignatureTypesDialogOpen: false},
			() => this.props.onChangeSigners(signers));
	}

	onOpenEmailMessageDialog = () => {
		this.setState({emailMessageDialogOpen: true});
	}

	onCloseEmailMessageDialog = () => {
		this.setState({emailMessageDialogOpen: false});
	}

	onUpdateEmailMessageDialog = (headers) => {
		this.props.onChangeSignersCustomEmailHeaders(headers);
		this.setState({emailMessageDialogOpen: false});
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {}
	}
)(EditorSignersComponent)));
