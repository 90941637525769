import React, {Component} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link} from "@mui/material";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class TacDialog extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const sessionInfo = this.props.sessionInfo;
		if (!sessionInfo) {
			return null;
		}

		const termsAndConditionsDialogOpen = !!sessionInfo && !(sessionInfo?.termsAndConditionsAccepted);

		return <Dialog
			open={termsAndConditionsDialogOpen}
			onClose={this.onDialogNoClose}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{this.props.t('tac.title')}</DialogTitle>
			<DialogContent>
				<Box>{this.props.t('tac.agree')}</Box>
				<Box>
					<ul>
						{['tac.general', 'tac.privacy'].map((key, index) =>
							<li key={index}>
								{this.props.t(key).split('$').map((v, index) => {
									if (v.indexOf('link') === 0) {
										const elements = v.split(';');
										return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
									}
								})}
							</li>)}
					</ul>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" onClick={this.props.onSessionAcceptTermsAndConditions} autoFocus>
					{this.props.t('tac.accept')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onDialogNoClose = () => {
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
		}
	},
	dispatch => {
		return {
			onSessionAcceptTermsAndConditions: () => {
				dispatch({
					type: 'SESSION_ACCEPT_TERMS_AND_CONDITIONS'
				})
			},
		}
	}
)(TacDialog));