import {useTranslation, withTranslation} from "react-i18next";
import {GridActionsCellItem, GridRowModes, GridToolbarContainer} from "@mui/x-data-grid-pro";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, {useCallback} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import {v4 as uuidv4} from "uuid";

function EditToolbar(props) {
	const {t} = useTranslation();
	const {setRows, setRowModesModel, setPaginationModel} = props;

	const handleClick = () => {
		const id = uuidv4();
		const newRow = {id, key: '', value: '', isNew: true};
		
		setRows((oldRows) => [newRow, ...oldRows]); // Add new row on top
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: {mode: GridRowModes.Edit, fieldToFocus: 'key'},
		}));

		// navigate to first page to make sure the new row is visible
		setPaginationModel((oldModel) => ({...oldModel, page: 0}));
	};

	return (
		<GridToolbarContainer>
			<Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
				{t('user.settingsUserMetaAttributeNew')}
			</Button>
		</GridToolbarContainer>
	);
}

const UserMetaAttributeSettingsComponent = ({settings, onUserChangeSetting}) => {
	const {t} = useTranslation();

	const [rows, setRows] = React.useState(settings.metaFields.map(mf => ({
		...mf,
		id: uuidv4()
	})));
	const [rowModesModel, setRowModesModel] = React.useState({});

	const [paginationModel, setPaginationModel] = React.useState({
		pageSize: 10,
		page: 0,
	});

	const onChangeMetaFields = useCallback((fields) => {
		onUserChangeSetting('metaFields', null, fields);
		setRows(fields);
	}, [onUserChangeSetting]);

	const handleDeleteClick = (id) => () => {
		onChangeMetaFields(rows.filter((row) => row.id !== id));
	};

	const processRowUpdate = (newRow) => {
		const updatedRow = {...newRow, isNew: false};
		onChangeMetaFields(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const columns = [
		{
			field: 'id',
			editable: false
		},
		{
			field: 'key',
			headerName: t('user.settingsUserMetaAttributeKey'),
			editable: true,
			flex: 1
		},
		{
			field: 'value',
			headerName: t('user.settingsUserMetaAttributeValue'),
			editable: true,
			flex: 1
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: t('user.actions'),
			width: 70,
			cellClassName: 'actions',
			getActions: ({id}) => {
				return [
					<GridActionsCellItem
						variant="contained"
						color="primary"
						icon={<DeleteIcon/>}
						label={t('delete')}
						onClick={handleDeleteClick(id)}
					/>,
				];
			},
		}
	];

	return <DataGrid
		autoHeight
		disableColumnSelector
		disableColumnFilter
		disableRowSelectionOnClick

		pagination
		paginationModel={paginationModel}
		onPaginationModelChange={setPaginationModel}

		rows={rows}
		columns={columns}
		editMode="row"
		rowModesModel={rowModesModel}
		onRowModesModelChange={handleRowModesModelChange}
		processRowUpdate={processRowUpdate}
		slots={{
			toolbar: EditToolbar,
		}}
		slotProps={{
			toolbar: {setRows, setRowModesModel, setPaginationModel},
		}}

		initialState={{
			columns: {
				columnVisibilityModel: {
					id: false
				},
			},
		}}
		pageSizeOptions={[10]}
		density="compact"
	/>
}

export default withTranslation()(UserMetaAttributeSettingsComponent);