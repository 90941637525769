import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Button, Snackbar} from "@mui/material";
import {withTranslation} from "react-i18next";

class SessionVersionOutOfSyncBanner extends Component {

	render() {
		return <Snackbar open={this.props.open}
						 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
						 >
			<Alert severity="warning"
				   sx={{width: '100%'}}
				   action={<Button size="small" onClick={() => window.location.reload(true)}>
					   {this.props.t('session.versionOutOfSyncRefresh')}
				   </Button>}
				   >
				{this.props.t('session.versionOutOfSync')}
			</Alert>
		</Snackbar>
	}

}

export default withTranslation()(connect(
	state => {
		return {
			open: !!state.session?.info?.buildVersion && !!process.env.REACT_APP_BUILD_VERSION && state.session?.info?.buildVersion !== process.env.REACT_APP_BUILD_VERSION,
		}
	},
	dispatch => {
		return {}
	}
)(SessionVersionOutOfSyncBanner))
