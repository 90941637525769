import React, {Component} from "react";
import {Button, Link, Snackbar} from "@mui/material";
import {withTranslation} from "react-i18next";

class CookieBanner extends Component {

	constructor(props) {
		super(props);

		this.state = {
			cookieConsentOpen: 'true' !== localStorage.getItem('cookie-consent-given'),
		};
	}

	render() {
		return <Snackbar
			open={this.state.cookieConsentOpen}
			onClose={this.onCookieConsentClose}
			message={
				this.props.t('cookie.message').split('$').map((v, index) => {
					if (v.indexOf('link') === 0) {
						const elements = v.split(';');
						return <Link key={index} target="_blank" href={elements[1]}>{elements[2]}</Link>
					} else
						return <span key={index}>{v}</span>
				})
			}
			action={<Button size="small"
							onClick={this.onCookieConsentAccept}>{this.props.t('cookie.accept')}</Button>}
		/>
	}

	onCookieConsentClose = () => {
	}

	onCookieConsentAccept = () => {
		localStorage.setItem('cookie-consent-given', 'true');
		this.setState({cookieConsentOpen: false});
	}
}

export default withTranslation()(CookieBanner);
