import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Box, Button, Checkbox, FormControlLabel, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import HelpIcon from '@mui/icons-material/Help';
import LoadingComponent from "../common/LoadingComponent";
import ServerErrorComponent from "../common/ServerErrorComponent";
import {SIGNATURE_TYPES} from "../common/Constants";
import EditorFieldStyleComponent from "../editor/EditorFieldStyleComponent";
import AddIcon from "@mui/icons-material/Add";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro/DataGridPro/DataGridPro";
import CompanyFormFieldValidatorDialog from "./CompanyFormFieldValidatorDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const VISUAL_SIGNATURE_FONTS = ['INDIEFLOWER', 'GREATVIBES', 'QWIGLEY', 'ARTYSIGNATURE'];

const DEFAULT_STATE = {
	formFieldValidatorDialogOpen: false,
	formFieldCurrentValidatorId: null
}

class CompanyGeneralSettingsComponent extends Component {

	constructor(props) {
		super(props);

		this.companySettingsValues = [
			'ALWAYS_AVAILABLE',
			'DEFAULT_AVAILABLE',
			'DEFAULT_UNAVAILABLE',
			'NEVER_AVAILABLE'
		]

		this.state = DEFAULT_STATE;
	}

	componentDidMount() {
		this.props.onCompanyFetchGeneralSettings();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		if (!this.props.companyGeneralSettings) {
			return <LoadingComponent/>;
		}

		const signingSettings = this.props.companyGeneralSettings?.signingSettings;
		const approvalSettings = this.props.companyGeneralSettings?.approvalSettings;
		const userSettings = this.props.companyGeneralSettings?.userSettings;
		const documentSettings = this.props.companyGeneralSettings?.documentSettings;
		const expirationSettings = this.props.companyGeneralSettings?.expirationSettings;

		const formFieldDataGridColumns = [
			{
				field: 'id',
			},
			{
				field: 'systemValidator',
			},
			{
				field: 'name',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorName'),
				editable: false,
				width: 200,
			},
			{
				field: 'formFieldType',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorType'),
				editable: false,
				width: 250,
				valueGetter: (data) => this.props.t('company.generalSettingsFormFieldValidatorType_' + data.value)
			},
			{
				field: 'format',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorFormat'),
				editable: false,
				flex: 1,
			},
			{
				field: 'actions',
				headerName: this.props.t('company.generalSettingsFormFieldValidatorActions'),
				editable: false,
				sortable: false,
				disableColumnMenu: true,
				width: 100,
				renderCell: (cellValues) => (<Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.generalSettingsFormFieldValidatorEdit')}
						onClick={() => this.onOpenDialogForValidatorEditing(!!cellValues.row.id ? cellValues.row.id : cellValues.row.referenceId)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('company.generalSettingsFormFieldValidatorDelete')}
						disabled={cellValues.row.systemValidator}
						onClick={() => this.onRemoveFormFieldValidator(cellValues.row)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>)
			}
		];


		return <Box sx={{mt: 2}}>
			<Typography
				variant="h6">{this.props.t('company.generalSettingsFor') + ' ' + this.props.sessionInfo.companyName}</Typography>
			<ServerErrorComponent serverError={this.props.companyServerError}/>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsUsers')}</Typography>
				<Box sx={{display: 'flex', flexDirection: 'column', ml: 2, mt: 1}}>
					<Box><FormControlLabel
						control={<Checkbox checked={userSettings.oidcLoginOnly}
										   onChange={this.onChangeOidcLoginOnly}/>}
						label={this.props.t('company.generalSettingsApplicationOidcOnlyLogin')}
						disabled={this.props.companyBusy}
					/>
					</Box>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={userSettings.guestUserAllowedToBecomeRegistered}
											   onChange={this.onChangeGuestUserAllowedToBecomeRegistered}/>}
							label={this.props.t('company.generalSettingsUsersAllowGuestToBecomeRegistered')}
							disabled={this.props.companyBusy}
						/>
					</Box>
				</Box>
			</Box>

			<Typography
				variant="h6" sx={{mt: 2}}>{this.props.t('company.generalSettingsSigningSettings')}</Typography>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsSigningMethods')}
					<Tooltip title={this.props.t('company.generalSettingsSigningMethodsHelp')}>
						<HelpIcon fontSize="small" sx={{ml: 1}}/>
					</Tooltip>
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					{Object.keys(SIGNATURE_TYPES)
						.map(type => (signingSettings?.signatureSettings || []).find(setting => setting.type === type))
						.filter(setting => !!setting)
						.map(setting => <Box key={setting.type}>
							<Box>{this.props.t('company.generalSettingsAllowSignature')
								.replace('$SIGNATURE', this.props.t('company.generalSettingsSignature_' + setting.type))}
							</Box>
							<ToggleButtonGroup
								color="primary"
								value={setting.value}
								exclusive
								onChange={(e, value) => this.onChangeSignatureValue(setting.type, value)}
								disabled={this.props.companyBusy}
							>
								{this.companySettingsValues.map(value =>
									<ToggleButton key={setting.type + value}
												  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
							</ToggleButtonGroup>
							{('DEFAULT_AVAILABLE' === setting.value || 'DEFAULT_UNAVAILABLE' === setting.value) &&
								<FormControlLabel
									control={<Checkbox checked={setting.applyToFolder}
													   onChange={(e, value) => this.onChangeSignatureApplyToFolder(setting.type, value)}/>}
									label={<Typography
										variant="body2">{this.props.t('company.generalSettingsSignatureApplyToFolders')}</Typography>}
									disabled={this.props.companyBusy}
									sx={{ml: 1}}
								/>}
						</Box>)}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsSigningPreferences')}
					<Tooltip title={this.props.t('company.generalSettingsSigningPreferencesHelp')}>
						<HelpIcon fontSize="small" sx={{ml: 1}}/>
					</Tooltip>
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box>{this.props.t('company.generalSettingsSigningReason')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.signingReasonPreference}
						exclusive
						onChange={this.onChangeSigningReasonValue}
						disabled={this.props.companyBusy}
					>
						{this.companySettingsValues.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>

					{(signingSettings.signingReasonPreference !== 'NEVER_AVAILABLE') &&
						<Box>
							<FormControlLabel
								control={<Checkbox checked={signingSettings.signingReasonLegallyRequired}
												   onChange={this.onChangeSigningReasonLegallyRequired}/>}
								label={this.props.t('company.generalSettingsSigningReasonMandatoryLegalNotice')}
								disabled={this.props.companyBusy}
								sx={{mt: 1}}
							/>
						</Box>
					}

					{(signingSettings.signingReasonPreference !== 'NEVER_AVAILABLE') && signingSettings.signingReasonLegallyRequired &&
						<TextField
							variant="outlined"
							fullWidth
							label={this.props.t('company.generalSettingsSigningReasonLegalNotice')}
							value={signingSettings.signingReasonLegalText || ''}
							onChange={this.onChangeSigningReasonLegalText}
							disabled={this.props.companyBusy}/>
					}
				</Box>
				<Box sx={{ml: 2, mt: 2}}>
					<Box>{this.props.t('company.generalSettingsSigningRemark')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.signingCommentPreference}
						exclusive
						onChange={this.onChangeSigningCommentValue}
						disabled={this.props.companyBusy}
					>
						{this.companySettingsValues.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsValue_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>
				</Box>
				<Box sx={{ml: 2, mt: 2}}>
					<Box>{this.props.t('company.generalSettingsSigningVisualSignatureFont')}</Box>
					<ToggleButtonGroup
						color="primary"
						value={signingSettings.visualSignatureFont}
						exclusive
						onChange={this.onChangeVisualSignatureFont}
						disabled={this.props.companyBusy}
					>
						{VISUAL_SIGNATURE_FONTS.map(value =>
							<ToggleButton key={value}
										  value={value}>{this.props.t('company.generalSettingsSigningVisualSignatureFont_' + value)}</ToggleButton>)}
					</ToggleButtonGroup>


					{!!signingSettings.fontPreviewBase64Data[signingSettings.visualSignatureFont] && <Box sx={{mt: 1}}>
						<Typography>{this.props.t('company.generalSettingsSigningVisualSignatureFontPreview')}</Typography>
						<Box>
							<img
								style={{
									border: '1px solid black',
									borderRadius: 5
								}}
								src={'data:image/png;base64,' + signingSettings.fontPreviewBase64Data[signingSettings.visualSignatureFont]}
								alt={this.props.t('company.generalSettingsSigningVisualSignatureFont_' + signingSettings.visualSignatureFont)}
							/>
						</Box>
					</Box>}
				</Box>
				<Box sx={{ml: 2, mt: 1, display: 'flex', flexDirection: 'column'}}>
					<FormControlLabel
						control={<Checkbox
							checked={signingSettings.addSigningMethodToSignature || signingSettings.addFullSigningInfo}
							onChange={this.onChangeAddSigningMethodToSignature}/>}
						label={this.props.t('company.generalSettingsSigningAddSigningMethod')}
						disabled={this.props.companyBusy || signingSettings.addFullSigningInfo}
					/>

					<FormControlLabel
						control={<Checkbox checked={signingSettings.addFullSigningInfo}
										   onChange={this.onChangeAddFullSigningInfo}/>}
						label={this.props.t('company.generalSettingsSigningAddFullSigningInfo')}
						disabled={this.props.companyBusy}
					/>
					<FormControlLabel
						control={<Checkbox checked={signingSettings.addImageToSignature}
										   onChange={this.onChangeAddImageToSignature}/>}
						label={this.props.t('company.generalSettingsSigningAddImage')}
						disabled={this.props.companyBusy}
					/>
				</Box>
				{signingSettings?.signingFieldStyle &&
					<Box sx={{ml: 2, mt: 1}}>
						<Typography sx={{fontWeight: 500, display: 'flex', alignItems: 'center', mb: 1}}>
							{this.props.t('company.generalSettingsDefaultSignatureFieldStyle')}
						</Typography>
						<EditorFieldStyleComponent style={signingSettings.signingFieldStyle}
												   onChangeStyle={this.onChangeStyle}/>
					</Box>
				}
			</Box>

			<Typography
				variant="h6" sx={{mt: 2}}>{this.props.t('company.generalSettingsDocumentSettings')}</Typography>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDescription')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box>
						<FormControlLabel
							control={<Checkbox checked={documentSettings.documentDescriptionMandatory}
											   onChange={this.onChangeDocumentDescriptionMandatory}/>}
							label={this.props.t('company.generalSettingsDescriptionMandatory')}
							disabled={this.props.companyBusy}
						/>
					</Box>
					<TextField
						variant="outlined"
						label={this.props.t('company.generalSettingsDescriptionText')}
						value={documentSettings.defaultDocumentDescription}
						onChange={this.onChangeDefaultDocumentDescription}
						disabled={this.props.companyBusy}
						autoComplete="off"
						fullWidth
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDocumentCollectionMarkAsReadMandatory')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.markAllDocumentsInCollectionAsReadMandatory}
										   onChange={this.onChangeDocumentCollectionMarkAsReadMandatory}/>}
						label={this.props.t('company.generalSettingsDocumentCollectionMarkAsReadMandatoryInfo')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsFormFieldValidatorSettings')}</Typography>
				<Box sx={{ml: 2}}>
					<Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 1}}>
						<Button variant="contained"
								onClick={this.onOpenDialogForValidatorAdding}
								startIcon={<AddIcon/>}
								sx={{mr: 1}}
								disabled={this.props.companyBusy}
								id="btn-formfieldvalidators-add"
						>
							{this.props.t('company.generalSettingsFormFieldValidatorAdd')}
						</Button>
					</Box>
					<DataGrid
						disableColumnFilter
						disableRowSelectionOnClick
						disableColumnSelector
						pagination

						initialState={{
							sorting: {
								sortModel: [{field: 'systemValidator', sort: 'desc'}, {field: 'id', sort: 'asc'}],
							},
							columns: {
								columnVisibilityModel: {
									id: false,
									systemValidator: false
								}
							},
							pagination: {
								paginationModel: {pageSize: 10, page: 0},
							},
						}}

						loading={this.props.companyBusy}

						getRowId={(row) => !!row.id ? row.id : row.referenceId}
						columns={formFieldDataGridColumns}
						rows={documentSettings.formFieldValidators || []}
						pageSizeOptions={[10]}
						density="compact"
						autoHeight
					/>

					<CompanyFormFieldValidatorDialog
						formFieldValidator={documentSettings.formFieldValidators?.find(validator => (validator.id === this.state.formFieldCurrentValidatorId || validator.referenceId === this.state.formFieldCurrentValidatorId))}
						open={this.state.formFieldValidatorDialogOpen}
						onClose={this.onCloseValidatorDialog}
						onConfirm={this.onEditFormFieldValidator}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography
					sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsApprovalPreferences')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={approvalSettings.showSignerInfo}
										   onChange={this.onChangeShowSignerInfo}/>}
						label={this.props.t('company.generalSettingsApprovalShowSignerInfo')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsExpiration')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox
							checked={expirationSettings.pendingApprovalAndSignaturesExpirationEnabled || false}
							onChange={this.onChangePendingApprovalAndSignaturesExpirationEnabled}/>}
						label={this.props.t('company.generalSettingsExpirationEnable')}
						disabled={this.props.companyBusy}
					/>
					{expirationSettings.pendingApprovalAndSignaturesExpirationEnabled &&
						<Box sx={{display: 'flex', alignItems: 'center'}}>
							<TextField
								variant="outlined"
								size="small"
								value={expirationSettings.pendingApprovalAndSignaturesExpirationInDays}
								onChange={this.onChangePendingApprovalAndSignaturesExpirationInDays}
								sx={{mr: 1, width: 100}}
								disabled={this.props.companyBusy}
							/>
							<span>{this.props.t('company.generalSettingsExpirationDays')}</span>
						</Box>}
					{expirationSettings.pendingApprovalAndSignaturesExpirationEnabled &&
						<>
							<FormControlLabel
								control={<Checkbox
									checked={expirationSettings.allowFoldersToDefineExpirations || false}
									onChange={this.onChangeAllowFoldersToDefineExpirations}/>}
								label={this.props.t('company.generalSettingsAllowFoldersToDefineExpirations')}
								disabled={this.props.companyBusy}
							/>
							<br/>
							<FormControlLabel
								control={<Checkbox
									checked={expirationSettings.allowDocumentsToDefineExpirations || false}
									onChange={this.onChangeAllowDocumentsToDefineExpirations}/>}
								label={this.props.t('company.generalSettingsAllowDocumentsToDefineExpirations')}
								disabled={this.props.companyBusy}
							/>
						</>}
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700, display: 'flex', alignItems: 'center'}}>
					{this.props.t('company.generalSettingsDocumentCleanup')}
					<Tooltip title={this.props.t('company.generalSettingsDocumentCleanupHelp')}>
						<HelpIcon fontSize="small" sx={{ml: 1}}/>
					</Tooltip>
				</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<TextField
							variant="outlined"
							size="small"
							value={documentSettings.cleanupOfProcessedDocumentsInDays}
							onChange={this.onChangeCleanupOfProcessedDocumentsInDays}
							sx={{mr: 1, width: 100}}
							disabled={this.props.companyBusy}
						/>
						<span>{this.props.t('company.generalSettingsDocumentCleanupDaysProcessed')}</span>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<TextField
							variant="outlined"
							size="small"
							value={documentSettings.cleanupOfPendingDocumentsInDays}
							onChange={this.onChangeCleanupOfPendingDocumentsInDays}
							sx={{mr: 1, width: 100}}
							disabled={this.props.companyBusy}
						/>
						<span>{this.props.t('company.generalSettingsDocumentCleanupDaysPending')}</span>
					</Box>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.differentCleanupSettingsForFoldersAllowed}
										   onChange={this.onChangeDifferentCleanupSettingsForFoldersAllowed}/>}
						label={this.props.t('company.generalSettingsDocumentCleanupAllowFolderSettings')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>

			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsForwarding')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.documentForwardingAllowed}
										   onChange={this.onChangeDocumentForwardingAllowed}/>}
						label={this.props.t('company.generalSettingsDocumentForwardingAllowed')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>
			<Box sx={{mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('company.generalSettingsDownloading')}</Typography>
				<Box sx={{ml: 2, mt: 1}}>
					<FormControlLabel
						control={<Checkbox checked={documentSettings.signerNeedsSignatureToDownload}
										   onChange={this.onChangeSignerNeedsSignatureToDownload}/>}
						label={this.props.t('company.generalSettingsSignerNeedsSignatureToDownload')}
						disabled={this.props.companyBusy}
					/>
				</Box>
			</Box>


			<Box sx={{mt: 2}}>
				<Button variant="contained" onClick={this.onSave} id="btn-settings-save"
						startIcon={<SaveIcon/>}>{this.props.t('save')}</Button>
			</Box>
		</Box>
	}

	onChangeSignatureValue = (type, value) => {
		const signatureSettings = this.props.companyGeneralSettings.signingSettings.signatureSettings.map(setting => {
			if (setting.type !== type) {
				return setting;
			} else {
				return {
					...setting,
					value
				}
			}
		});
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signatureSettings', signatureSettings);
		}
	}

	onChangeSignatureApplyToFolder = (type, applyToFolder) => {
		const signatureSettings = this.props.companyGeneralSettings.signingSettings.signatureSettings.map(setting => {
			if (setting.type !== type) {
				return setting;
			} else {
				return {
					...setting,
					applyToFolder
				}
			}
		});
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signatureSettings', signatureSettings);
	}

	onChangeSigningReasonValue = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonPreference', value);
		}
	}

	onChangeSigningReasonLegallyRequired = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonLegallyRequired', value);
	}

	onChangeSigningReasonLegalText = (e) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingReasonLegalText', e.target.value);
	}

	onChangeSigningCommentValue = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingCommentPreference', value);
		}
	}

	onChangeVisualSignatureFont = (e, value) => {
		if (!!value) {
			this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'visualSignatureFont', value);
		}
	}

	onChangeOidcLoginOnly = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('userSettings', 'oidcLoginOnly', value);
	}

	onChangeGuestUserAllowedToBecomeRegistered = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('userSettings', 'guestUserAllowedToBecomeRegistered', value);
	}

	onChangePendingApprovalAndSignaturesExpirationEnabled = (e, value) => {
		if (!value) {
			this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationInDays', 0);
			this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowFoldersToDefineExpirations', false);
		}
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationEnabled', value);
	}

	onChangePendingApprovalAndSignaturesExpirationInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'pendingApprovalAndSignaturesExpirationInDays', !value ? 0 : value);
	}

	onChangeAllowFoldersToDefineExpirations = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowFoldersToDefineExpirations', value);
	}

	onChangeAllowDocumentsToDefineExpirations = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('expirationSettings', 'allowDocumentsToDefineExpirations', value);
	}

	onChangeCleanupOfProcessedDocumentsInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'cleanupOfProcessedDocumentsInDays', !value ? 0 : value);
	}

	onChangeCleanupOfPendingDocumentsInDays = (e) => {
		const value = parseInt(e.target.value);
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'cleanupOfPendingDocumentsInDays', !value ? 0 : value);
	}

	onChangeDifferentCleanupSettingsForFoldersAllowed = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'differentCleanupSettingsForFoldersAllowed', value);
	}

	onChangeDocumentForwardingAllowed = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'documentForwardingAllowed', value);
	}

	onChangeDocumentDescriptionMandatory = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'documentDescriptionMandatory', value);
	}

	onChangeDefaultDocumentDescription = (e) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'defaultDocumentDescription', e.target.value);
	}

	onChangeDocumentCollectionMarkAsReadMandatory = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'markAllDocumentsInCollectionAsReadMandatory', value);
	}

	onChangeAddSigningMethodToSignature = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addSigningMethodToSignature', value);
	}

	onChangeAddFullSigningInfo = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addFullSigningInfo', value);
	}

	onChangeAddImageToSignature = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'addImageToSignature', value);
	}

	onChangeShowSignerInfo = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('approvalSettings', 'showSignerInfo', value);
	}

	onChangeStyle = (value) => {
		this.props.onCompanyChangeGeneralSettingsItem('signingSettings', 'signingFieldStyle', value);
	}

	onOpenDialogForValidatorAdding = () => {
		this.setState({formFieldValidatorDialogOpen: true, formFieldCurrentValidatorId: null});
	}

	onOpenDialogForValidatorEditing = (formFieldCurrentValidatorId) => {
		this.setState({formFieldCurrentValidatorId, formFieldValidatorDialogOpen: true});
	}

	onCloseValidatorDialog = () => {
		this.setState({formFieldValidatorDialogOpen: false});
	}

	onEditFormFieldValidator = (formFieldValidator) => {
		let validators = [...this.props.companyGeneralSettings.documentSettings.formFieldValidators];

		if (!!this.state.formFieldCurrentValidatorId) {
			validators = validators.map(tmpValidator =>
				(tmpValidator.id === this.state.formFieldCurrentValidatorId || tmpValidator.referenceId === this.state.formFieldCurrentValidatorId)
					? formFieldValidator
					: tmpValidator);
		} else {
			validators.push(formFieldValidator);
		}
		this.setState({
			formFieldValidatorDialogOpen: false,
			formFieldCurrentValidatorId: null
		}, () => this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'formFieldValidators', validators));
	}

	onRemoveFormFieldValidator = (formFieldValidator) => {
		const validatorId = !!formFieldValidator.id ? formFieldValidator.id : formFieldValidator.referenceId;
		let validators = [...this.props.companyGeneralSettings.documentSettings.formFieldValidators];
		const index = validators.findIndex(tmpValidator => (tmpValidator.id === validatorId || tmpValidator.referenceId === validatorId));
		if (index >= 0) {
			validators.splice(index, 1);
			this.setState({
				formFieldValidatorDialogOpen: false,
				formFieldCurrentValidatorId: null
			}, () => this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'formFieldValidators', validators));
		}
	}

	onChangeSignerNeedsSignatureToDownload = (e, value) => {
		this.props.onCompanyChangeGeneralSettingsItem('documentSettings', 'signerNeedsSignatureToDownload', value);
	}

	onSave = () => {
		this.props.onCompanyUpdateGeneralSettings(this.props.companyGeneralSettings);
	}

}

export default withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,

			companyBusy: state.company.busy,
			companyServerError: state.company.serverError,
			companyGeneralSettings: state.company.generalSettings,
		}
	},
	dispatch => {
		return {
			onCompanyFetchGeneralSettings: () => {
				dispatch({
					type: 'COMPANY_FETCH_GENERAL_SETTINGS'
				});
			},
			onCompanyUpdateGeneralSettings: (settings) => {
				dispatch({
					type: 'COMPANY_UPDATE_GENERAL_SETTINGS',
					settings
				});
			},
			onCompanyChangeGeneralSettingsItem: (key, subkey, value) => {
				dispatch({
					type: 'COMPANY_CHANGE_GENERAL_SETTINGS_ITEM',
					key,
					subkey,
					value
				});
			},
		}
	}
)(CompanyGeneralSettingsComponent));
