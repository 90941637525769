import React, {Component} from "react";
import {withRouter} from "react-router";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import UploadFileComponent from "../common/FileUploadComponent";

const DEFAULT_STATE = {
    name: '',
    type: 'SINGLE_DOC_NO_PDF',
    selectedFolderId: null,
    uploadBusy: false,
    uploadProgress: 0,
    uploadError: null,
    file: null
}

class TemplateCreationDialog extends Component {

    constructor(props) {
        super(props);

        this.state = DEFAULT_STATE;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open && !prevProps.open) {
            this.setState(DEFAULT_STATE);
            this.props.onFetchCreationInfo();
        } else if (this.props.open && !this.state.selectedFolderId && !!this.props.templateCreationInfo) {
            const selectedFolderId = this.determineInitialFolderId();
            if (selectedFolderId !== this.state.selectedFolderId) {
                this.setState({selectedFolderId});
            }
        }
    }

    render() {
        return <Dialog open={this.props.open}
                       onClose={this.onClose}
                       onKeyUp={this.onKeyUp}
                       disableRestoreFocus
                       fullWidth
                       maxWidth="md">
            <DialogTitle>{this.props.t('template.create')}</DialogTitle>
            <DialogContent>
                {this.state.uploadError && <Box sx={{ mt: 1, mb: 1 }}>
                    <Alert severity="error">{this.props.t(this.state.uploadError)}</Alert>
                </Box>}

                <Stepper activeStep={-1} orientation="vertical">
                    <Step active>
                        <StepLabel>{this.props.t('template.createName')}</StepLabel>
                        <StepContent>
                            <TextField
                                variant="outlined"
                                label={this.props.t('template.name')}
                                required
                                value={this.state.name}
                                onChange={this.onChangeName}
                                autoComplete="off"
                                fullWidth
                                autoFocus
                                id="input-template-name-text"
                                inputProps={{maxLength: 251}}
                            />
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel>{this.props.t('template.createFolder')}</StepLabel>
                        <StepContent>
                            <FormControl fullWidth>
                                <Select
                                    value={this.state.selectedFolderId || ''}
                                    onChange={this.onChangeSelectedFolderId}
                                    disabled={this.props.templateBusy}
                                    inputProps={{id: 'input-template-folder-select'}}
                                >
                                    {(this.props.templateCreationInfo?.folders || [])
                                        .filter(folder => folder.userCanCreateAndEditDocuments)
                                        .map(folder =>
                                                <MenuItem key={folder.id} value={folder.id}>
                                                    {folder.name}
                                                </MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                        </StepContent>
                    </Step>

                    <Step active>
                        <StepLabel>{this.props.t('template.createType')}</StepLabel>
                        <StepContent>
                            <FormControl fullWidth>
                                <RadioGroup
                                    value={this.state.type}
                                    onChange={this.onChangeTemplateType}
                                >
                                    <FormControlLabel value="SINGLE_DOC_NO_PDF" control={<Radio sx={{p: 0.5}}/>}
                                                      label={this.props.t('template.typeSingleDocNoPdf')}/>
                                    <FormControlLabel value="SINGLE_DOC_PDF" control={<Radio sx={{p: 0.5}}/>}
                                                      label={this.props.t('template.typeSingleDocPdf')}/>
                                    <FormControlLabel value="COLLECTION" control={<Radio sx={{p: 0.5}}/>}
                                                      label={this.props.t('template.typeCollection')}/>
                                </RadioGroup>
                            </FormControl>
                        </StepContent>
                    </Step>

                    {this.state.type === 'SINGLE_DOC_PDF' && <Step active>
                        <StepLabel>{this.props.t('template.uploadDoc')}</StepLabel>
                        <StepContent>
                            <UploadFileComponent disabled={this.state.uploadBusy}
                                                 onAddFile={this.onAddFile}
                                                 uploadProgress={this.state.uploadProgress}
                                                 uploadedFile={this.state.file}
                                                 uploadBoxType={'static'}
                                                 accepted={"application/pdf,.doc,.docx"}/>
                        </StepContent>
                    </Step>}


                    <Step active>
                        <StepLabel>{this.props.t('template.createFinish')}</StepLabel>
                        <StepContent>
                            <Typography>{this.props.t('template.createFinishDescription')}</Typography>
                        </StepContent>
                    </Step>
                </Stepper>
            </DialogContent>

            <DialogActions>
                <Button onClick={this.onClose} id="btn-settings-cancel">{this.props.t('cancel')}</Button>
                <Button variant="contained"
                        disabled={!this.canContinue()}
                        onClick={this.onCreate}
                        id="btn-settings-confirm"
                >
                    {this.props.t('template.create')}
                </Button>
            </DialogActions>

        </Dialog>
    }

    determineInitialFolderId = () => {
        return (this.props.templateCreationInfo?.folders || [])
            .filter(folder => folder.userCanCreateAndEditDocuments)
            .map(folder => folder.id)
            .at(0);
    }

    onChangeSelectedFolderId = (e) => {
        this.setState({selectedFolderId: e.target.value});
    }

    onChangeTemplateType = (event) => {
        this.setState({type: event.target.value, file: null, uploadError: null});
    }

    onUploadTemplate = (templateName, file) => {
        this.setState({uploadBusy: true});

        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (event) => {
            if (event.lengthComputable) {
                this.setState({uploadProgress: Math.round((event.loaded * 100) / event.total)});
            }
        });

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    if (!xhr.responseText) {
                        this.props.onSessionDestroy();
                        return;
                    }
                    const response = JSON.parse(xhr.responseText);
                    if (!response.id) {
                        const uploadError = !response.error ? 'template.uploadErrorGeneralError' : 'serverError.' + response.error;
                        this.setState({uploadError, uploadProgress: 0, uploadBusy: false});
                    } else {
                        this.onNavigateToEditor(response.id);
                    }
                } else {
                    const response = JSON.parse(xhr.responseText);
                    const uploadError = !response.error ? 'template.uploadErrorGeneralError' : 'serverError.' + response.error;
                    this.setState({uploadError: uploadError, uploadBusy: false});
                }
            }
        };

        xhr.open('POST', '/api/internal/template/create-new', true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        const formData = new FormData();
        formData.append('name', templateName);
        formData.append('folderId', this.state.selectedFolderId);

        if (this.state.type === 'SINGLE_DOC_PDF') {
            // append the file
            formData.append('file', file);
        } else if (this.state.type === 'COLLECTION') {
            formData.append('collection', 'true');
        }

        xhr.send(formData);
    }

    onNavigateToEditor = (id) => {
        this.props.history.push(`/template/editor/id=${id}`);
    }

    onAddFile = (file) => {
        if (this.state.type !== 'SINGLE_DOC_PDF') {
            return;
        }
        if (file?.size >= 104857600) {
            this.setState({uploadError: this.props.t('upload.invalidSize').replace('{0}', (104857600 / 1024 / 1024) + 'MB')});
        } else {
            const templateName = this.state.name;
            this.setState({
                file,
                name: templateName.length === 0 ? file.name : templateName
            });
        }
    }

    canContinue = () => {
        return !this.state.uploadBusy && !!this.state.name.trim() && !!this.state.selectedFolderId &&
            (this.state.type === 'SINGLE_DOC_NO_PDF' || this.state.type === 'SINGLE_DOC_PDF' && !!this.state.file || this.state.type === 'COLLECTION');
    }

    onKeyUp = (e) => {
        if (e.key === 'Enter' && this.canContinue) {
            this.onCreate();
        }
    }

    onChangeName = (e) => {
        this.setState({name: e.target.value});
    }

    onCreate = () => {
        this.onUploadTemplate(this.state.name, this.state.file);
    }

    onClose = (e, reason) => {
        if (reason !== 'backdropClick') {
            this.props.onClose();
        }
    }

}

export default withRouter(withTranslation()(connect(
    state => {
        return {
            templateCreationInfo: state.template.creationInfo,
            templateBusy: state.template.busy,
        }
    },
    dispatch => {
        return {
            onFetchCreationInfo: (request) => {
                dispatch({
                    type: 'TEMPLATE_FETCH_CREATION_INFO',
                    request,
                });
            },
            onSessionDestroy: () => {
                dispatch({
                    type: 'SESSION_DESTROY'
                })
            }
        }
    }
)(TemplateCreationDialog)));
