import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Step, StepContent, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import UploadFileComponent from "../common/FileUploadComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ConfirmationDialog from "../common/ConfirmationDialog";

const defaultStateGen = (props) => ({
	uploadProgress: 0,
	uploadBusy: false,
	file: null,
})


class DelegateFileUploadDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultStateGen(this.props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultStateGen(this.props));
		}
	}

	render() {
		if (!this.props.open || !this.props.delegate) {
			return;
		}

		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="md"
			>
				<DialogTitle>{this.props.t('delegates.uploadFile')}</DialogTitle>
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('delegates.delegate')}</StepLabel>
							<StepContent>
								<TextField
									variant="outlined"
									required
									value={this.props.delegate.firstName + ' ' + this.props.delegate.lastName}
									disabled
									fullWidth
								/>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('delegates.file')}</StepLabel>
							<StepContent>
								{!this.props.delegate.fileName && <>
									<Typography>{this.props.t('delegates.fileUploadDescription')}</Typography>
									<UploadFileComponent disabled={false}
														 onAddFile={this.onAddFile}
														 uploadProgress={this.state.uploadProgress}
														 uploadedFile={this.state.file}
														 uploadBoxType={'static'}
														 accepted={"application/pdf"}/>
								</>}

								{this.state.uploadError && <Box sx={{mt: 1, mb: 1}}>
									<Alert severity="error">{this.props.t(this.state.uploadError)}</Alert>
								</Box>}

								{!!this.props.delegate.fileName && <>
									<Typography>{this.props.t('delegates.fileDescription')}</Typography>
									<Box sx={{display: 'flex', gap: 1, mt: 1}}>
										<TextField
											variant="outlined"
											required
											value={this.props.delegate.fileName}
											disabled
											fullWidth
										/>

										<IconButton onClick={() => this.props.onDownloadFile(this.props.delegateListId, this.props.delegate.id)}
													id="btn-delegate-file-download"
													variant="contained"
													disabled={this.props.companyBusy}
													title={this.props.t('delegates.fileDownload')}>
											<DownloadIcon/>
										</IconButton>
										<IconButton onClick={this.onOpenDeleteDialog}
													id="btn-delegate-file-delete"
													variant="contained"
													disabled={this.props.companyBusy}
													title={this.props.t('delegates.fileDelete')}
										>
											<DeleteIcon/>
										</IconButton>
									</Box></>}

							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-delegate-edit-cancel">{this.props.t('close')}</Button>
					{!this.props.delegate.fileName && <Button onClick={this.onUploadFile}
																			 variant="contained"
																			 disabled={!this.state.file || this.state.uploadBusy}
																			 id="btn-delegate-edit-cancel">{this.props.t('delegates.fileUpload')}</Button>}
				</DialogActions>
			</Dialog>

			<ConfirmationDialog
				title={this.props.t('delegates.fileDelete')}
				confirm={this.props.t('delegates.fileDeleteConfirm')}
				open={this.state.deleteDialogOpen}
				onClose={this.onCloseDeleteDialog}
				onConfirm={this.onConfirmDeletion}
			/>

		</Fragment>
	}

	onAddFile = (file) => {
		if (file?.size >= 10485760) {
			this.setState({uploadError: this.props.t('upload.invalidSize').replace('{0}', (10485760 / 1024 / 1024) + 'MB')});
		} else {
			this.setState({
				file,
				name: file.name
			});
		}
	}

	onUploadFile = () => {
		if (!this.state.file) {
			return;
		}

		this.setState({uploadBusy: true});

		const xhr = new XMLHttpRequest();

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				this.setState({uploadProgress: Math.round((event.loaded * 100) / event.total)});
			}
		});

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				if (xhr.status >= 200 && xhr.status < 300) {
					this.setState({uploadError: null, uploadBusy: false, uploadProgress: 0, file: null}, this.props.onUploadSuccess);
				} else {
					const response = JSON.parse(xhr.responseText);
					const uploadError = !response.error ? 'upload.unknownError' : 'serverError.' + response.error;
					this.setState({uploadError: uploadError, uploadProgress: 0, uploadBusy: false});
				}
			}
		};

		xhr.open('PUT', this.props.getUploadFileUrl(this.props.delegateListId, this.props.delegate.id), true);
		xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

		const formData = new FormData();
		formData.append('name', this.state.name);

		// append the file
		formData.append('file', this.state.file);

		xhr.send(formData);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}

	onOpenDeleteDialog = () => {
		this.setState({deleteDialogOpen: true});
	}

	onCloseDeleteDialog = () => {
		this.setState({deleteDialogOpen: false});
	}

	onConfirmDeletion = () => {
		this.setState({deleteDialogOpen: false}, () => this.props.onDeleteFile(this.props.delegateListId, this.props.delegate.id));
	}
}

export default withTranslation()(connect(
	state => {
		return {
			companyBusy: state.company.busy,
		}
	},
	dispatch => {
		return {}
	}
)(DelegateFileUploadDialog));
