import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {useDrag} from "react-dnd";
import {Alert, Box, Checkbox, Chip, Fab, FormControlLabel, IconButton, Typography} from "@mui/material";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DownloadIcon from "@mui/icons-material/Download";
import EditorAddUserDialog from "./EditorAddUserDialog";
import {SIGNATURE_TYPES} from "../common/Constants";
import {DEFAULT_SIGN_EMAIL_HEADERS} from "../common/EmailConstants";
import {SIGNER_SMS_OTP_ERROR} from "./EditorUtils";
import EditorAddSignerGroupDialog from "./EditorAddSignerGroupDialog";
import EditorBulkSignatureTypesDialog from "./EditorBulkSignatureTypesDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import {FieldsContainer, FormFieldsContainer, SignerSettingsComponent} from "./EditorCommonComponents";
import SplitButton from "../common/SplitButton";
import EmailIcon from "@mui/icons-material/Email";
import {EditorEmailMessageDialog} from "./EditorEmailMessageDialog";
import UserImportLookupDialog from "../user/UserImportLookupDialog";

const EXTRACT_SIGNERS = (document) => {
	return (document?.children || [])
		.filter(doc => doc.childType === 'MULTI_SIGN')
		.map(child => ({
			...child.signers.at(0),
			documentId: child.id
		}));
}

const DraggableMultiSignFieldWrapper = ({onFieldDropped, signatureTypes, sx, children}) => {
	const [, drag] = useDrag(() => ({
		type: 'signer',
		item: 'multisign',
		end: (signer, monitor) => {
			const dropResult = monitor.getDropResult()
			if (signer && dropResult) {
				const {pageIndex, rect} = dropResult;
				onFieldDropped({signer: 'multisign', pageIndex, rect});
			}
		},
	}))
	return <Box ref={drag} sx={{border: '1px dashed #76bde9', cursor: 'grab', ...(!!sx && sx)}}>
		{children}
	</Box>
}

class EditorMultiSignComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			signerAddDialogOpen: false,
			signerGroupAddDialogOpen: false,
			signerImportDialogOpen: false,
			bulkSignatureTypesDialogOpen: false,
			selectedSignerIdForSettings: '',
			emailMessageDialogOpen: false,
		};
	}

	render() {
		const document = this.props.document;
		const readOnly = document.readOnly;

		const signers = EXTRACT_SIGNERS(document);

		const signatureField = this.props.signatureField;

		const multiSigner = {
			id: 'multisign',
			signatureField,
			paraphFields: this.props.paraphFields,
			extraSignatureFields: this.props.extraSignatureFields,
			formFields: this.props.formFields
		}

		const defaultEmailHeader = (DEFAULT_SIGN_EMAIL_HEADERS[this.state.emailLanguage] || '')
			.replace('{0}', this.props.sessionInfo?.userFullName)
			.replace('{1}', this.props.sessionInfo?.companyName)
		;
		return <Box>
			<Typography sx={{mt: 1}}>{this.props.t('editor.multiSignDescription')}</Typography>

			{!readOnly && !signatureField && <>
				<Typography sx={{fontWeight: 700, mt: 1, mb: 1}}>
					{this.props.t('editor.multiSignDefaultSignatureTypes')}
				</Typography>
				<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, justifyContent: 'center'}}>
					{Object.keys(SIGNATURE_TYPES).map(type => {
						if (document.enabledSignatureTypes.indexOf(type) < 0) return null;

						const checked = this.props.signatureTypes.indexOf(type) >= 0;
						const disabled = checked && this.props.signatureTypes.length <= 1;

						return <FormControlLabel
							key={type}
							control={<Checkbox
								checked={checked}
								onChange={(e) => this.onChangeSignatureType(type, e.target.checked)}
								disabled={disabled}
							/>}
							label={<Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
								<img src={SIGNATURE_TYPES[type].img}
									 style={{width: '30px', height: 'auto'}}/>
								<Typography variant="body2">
									{this.props.t('editor.signingMethod_' + type)}
								</Typography>
							</Box>}
						/>
					})}
				</Box>

				<Typography
					sx={{fontWeight: 700, mt: 1}}>{this.props.t('editor.multiSignPlaceSignatureField')}</Typography>
				<Box>
					<DraggableMultiSignFieldWrapper
						sx={{display: 'flex', p: 1, mt: 1}}
						onFieldDropped={this.props.onSignatureFieldDropped}
					>
						<Box sx={{mr: 3, display: 'flex', alignItems: 'center'}}>
							<ZoomOutMapIcon fontSize="large"/>
						</Box>
						{Object.keys(SIGNATURE_TYPES).map(type => this.props.signatureTypes.indexOf(type) >= 0 ?
							<Box key={type} sx={{display: 'flex', alignItems: 'center'}}>
								<img src={SIGNATURE_TYPES[type].img}
									 style={{width: '30px', height: 'auto'}}/>
							</Box> : null)
						}
					</DraggableMultiSignFieldWrapper>
				</Box>
			</>}

			{!!signatureField && <>
				<Typography sx={{
					fontWeight: 700,
					mt: 2,
					mb: 1
				}}>{this.props.t('editor.signingSignatureFieldOptions')}</Typography>
				{(!readOnly || multiSigner?.paraphFields?.length > 0) && <Box sx={{p: 1, mb: 1}}>
					<Typography variant="body" sx={{mt: 1, mb: 1}}>
						{this.props.t('editor.signingParaphField')}
					</Typography>
					<Box variant="outlined" sx={{mt: 0.5}}>
						<Typography
							variant="body2">{this.props.t('editor.signingParaphFieldDescription')}</Typography>
						<FieldsContainer
							readOnly={readOnly}
							signer={multiSigner}
							fields={multiSigner.paraphFields}
							onFieldRemove={this.props.onParaphFieldRemove}
							onFieldRemoveAll={this.props.onParaphFieldRemoveAll}
						/>

						{!readOnly && <DraggableMultiSignFieldWrapper
							onFieldDropped={this.props.onParaphFieldDropped}
							sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}
						>
							<ZoomOutMapIcon fontSize="large"/>
							<Typography
								variant="body2">{this.props.t('editor.signingParaphFieldPlaceOnDocument')}</Typography>
						</DraggableMultiSignFieldWrapper>}
					</Box>
				</Box>}
				{(!readOnly || multiSigner?.extraSignatureFields?.length > 0) && <Box sx={{p: 1, mb: 1}}>
					<Typography variant="body" sx={{mt: 1, mb: 1}}>
						{this.props.t('editor.signingExtraSignatureField')}
					</Typography>
					<Box>
						<FieldsContainer
							readOnly={readOnly}
							signer={multiSigner}
							fields={multiSigner.extraSignatureFields}
							onFieldRemove={this.props.onExtraSignFieldRemove}
							onFieldRemoveAll={this.props.onExtraSignFieldRemoveAll}
						/>
						{!readOnly && <DraggableMultiSignFieldWrapper
							onFieldDropped={this.props.onExtraSignFieldDropped}
							sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}
						>
							<ZoomOutMapIcon fontSize="large"/>
							<Typography
								variant="body2">{this.props.t('editor.signingExtraSignatureFieldPlaceOnDocument')}</Typography>
						</DraggableMultiSignFieldWrapper>}
					</Box>
				</Box>}
				{(!readOnly || multiSigner?.formFields?.length > 0) && <Box sx={{p: 1, mb: 1}}>
					<Typography variant="body" sx={{mt: 1, mb: 1}}>
						{this.props.t('editor.signingFormField')}
					</Typography>
					<Box>
						<FormFieldsContainer
							readOnly={readOnly}
							signer={multiSigner}
							onFormFieldRemove={this.props.onFormFieldRemove}
							onFormFieldRemoveAll={this.props.onFormFieldRemoveAll}
						/>
						{!readOnly && <DraggableMultiSignFieldWrapper
							signer={multiSigner}
							onFieldDropped={this.props.onFormFieldDropped}
							sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}
						>
							<ZoomOutMapIcon fontSize="large"/>
							<Typography
								variant="body2">{this.props.t('editor.signingFormFieldPlaceOnDocument')}</Typography>
						</DraggableMultiSignFieldWrapper>}
					</Box>
				</Box>}

				<Box sx={{display: 'flex', alignItems: 'center', gap: 1, mt: 2}}>
					<Box sx={{flexGrow: 1}}>
						<Typography sx={{fontWeight: 700}}>{this.props.t('editor.signingList')}</Typography>
					</Box>
					<SplitButton color="secondary" actions={[
						{
							title: this.props.t('editor.signingAddSigner'),
							action: this.onOpenSignerDialog,
							disabled: readOnly,
							icon: <AddIcon/>,
							id: "btn-editor-multisign-add-signer"
						},
						{
							title: this.props.t('editor.signingAddSignerGroup'),
							action: this.onOpenSignerGroupDialog,
							disabled: readOnly,
							icon: <AddIcon/>,
							id: "btn-editor-multisign-add-signergroup"
						},
						...(this.props.editorEditInfo.canImportUsers ? [{
							title: this.props.t('editor.signingImportSigners'),
							action: this.onOpenImportDialog,
							disabled: readOnly,
							icon: <FontAwesomeIcon icon={faFileCsv}/>,
							id: "btn-editor-multisign-import"
						}] : [])
					]}/>
					<Fab size="small"
						 color="secondary"
						 title={this.props.t('editor.signingBulkSignatureTypes')}
						 onClick={this.onOpenBulkSignatureTypesDialog}
						 disabled={readOnly || signers.length === 0}
					>
						<SettingsSuggestIcon fontSize="small"/>
					</Fab>
					<Fab size="small"
						 color="secondary"
						 title={this.props.t('editor.emailMessageHeaderSigners')}
						 onClick={this.onOpenEmailMessageDialog}
						 disabled={readOnly || signers.length === 0}
					>
						<EmailIcon fontSize="small"/>
					</Fab>
				</Box>

				{signers.length === 0 &&
					<Alert severity="info" sx={{mt: 1}}>{this.props.t('editor.signingListEmpty')}</Alert>}

				<Box sx={{mt: 1, display: 'flex', flexDirection: 'column', flexShrink: 0}}>
					{signers.map(signer => {
						const signerSmsOtpError = SIGNER_SMS_OTP_ERROR(document, signer);
						const showSignatureSettings =
							(signer.id === this.state.selectedSignerIdForSettings)
						;

						return <Box key={signer.id}>
							<Box sx={{display: 'flex', alignItems: 'center', p: 1}}>
								<PersonIcon fontSize="large"/>

								<Box sx={{flexGrow: 1, ml: 1}}>
									<Typography variant="body2">
										{signer.person.firstName + ' ' + signer.person.lastName}
										{!showSignatureSettings && Object.keys(SIGNATURE_TYPES).map(type =>
											(signer.signatureTypes.indexOf(type) >= 0 ?
												<img key={type} src={SIGNATURE_TYPES[type].img}
													 style={{marginLeft: '4px', width: '14px', height: 'auto'}}/>
												: null)
										)}
									</Typography>
									<Typography variant="body2">{signer.person.email}</Typography>

									{!!signer.capacityTitle && <Chip
										label={<Typography variant="body2">{signer.capacityTitle}</Typography>}
										size="small"/>}
								</Box>

								<Box
									sx={{
										pl: 2,
										pr: 2,
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<Chip
										label={<Typography variant="body2">
											{this.props.t('signing.signRequestState_' + (signer.signRequestState || 'NEW'))}
										</Typography>}
										size="small"/>
								</Box>

								<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
									{signerSmsOtpError && !showSignatureSettings &&
										<Typography
											color="error"
											variant="body2"
										>
											{this.props.t('editor.signingSmsOtpNumberError')}
										</Typography>
									}
								</Box>

								<IconButton
									size="small"
									color={signerSmsOtpError ? 'error' : 'primary'}
									title={this.props.t('editor.signingSignatureSettings')}
									onClick={() => this.onChangeSelectedSignerIdForSettings(signer.id)}
									disabled={(readOnly && !document?.canUpdateSigningMethods) || !document?.editPermission || signer.signed}
								>
									<SettingsIcon/>
								</IconButton>
								<IconButton
									size="small"
									color="primary"
									title={this.props.t('editor.signingDelete')}
									onClick={() => this.onSignerDelete(signer.id)}
									disabled={readOnly || signer.signed}
								>
									<DeleteIcon/>
								</IconButton>
								{signer.signed && <IconButton
									size="small"
									color="primary"
									title={this.props.t('editor.multiSignDownload')}
									onClick={() => this.props.onDocumentSingleDownloadArtifacts(signer.documentId, ['DOCUMENT'])}
								>
									<DownloadIcon/>
								</IconButton>}
							</Box>

							{showSignatureSettings &&
								<Box sx={{ml: '28px', pl: '34px', borderLeft: '1px dashed lightgrey', mb: 1}}>
									<SignerSettingsComponent
										document={document}
										signer={signer}
										readOnly={readOnly}
										onSignerChangeSignatureType={this.onSignerChangeSignatureType}
										onSignerChangeCapacityTitle={this.onSignerChangeCapacityTitle}
										onSignerChangeUseKnownOtpNumber={this.onSignerChangeUseKnownOtpNumber}
										onSignerChangeOtpNumber={this.onSignerChangeOtpNumber}
									/>
								</Box>}
						</Box>
					})}
				</Box>
			</>}

			<EditorAddUserDialog
				title={this.props.t('editor.signingAddSigner')}
				documentId={document.id}
				folderId={document.folderId}
				open={this.state.signerAddDialogOpen}
				onClose={this.onCloseSignerDialog}
				onSelect={this.onSelectUsers}
			/>

			<EditorAddSignerGroupDialog
				title={this.props.t('editor.signingAddSignerGroup')}
				folderId={document.folderId}
				open={this.state.signerGroupAddDialogOpen}
				onClose={this.onCloseSignerGroupDialog}
				onSelect={this.onSelectUsers}
			/>

			<UserImportLookupDialog
				title={this.props.t('editor.signingImportSigners')}
				open={this.state.signerImportDialogOpen}
				onClose={this.onCloseImportDialog}
				onSelect={this.onSelectUsers}
				canInviteUsers={this.props.editorEditInfo?.canInviteGuests || this.props.editorEditInfo?.canInviteRegisteredUsers}
				allowContacts
				folderId={document.folderId}
			/>

			<EditorBulkSignatureTypesDialog
				open={this.state.bulkSignatureTypesDialogOpen}
				onClose={this.onCloseBulkSignatureTypesDialog}
				possibleSignatureTypes={document.enabledSignatureTypes}
				onChangeSignatureTypes={this.onBulkChangeSignatureTypes}
			/>

			<EditorEmailMessageDialog
				open={!!this.state.emailMessageDialogOpen}
				onClose={this.onCloseEmailMessageDialog}
				onUpdate={this.onUpdateEmailMessageDialog}
				forSigning
				sessionInfo={this.props.sessionInfo}
				customEmailHeaders={this.props.multiSignCustomEmailHeaders}
			/>
		</Box>
	}

	onChangeSignatureType = (type, checked) => {
		const signatureTypes = this.props.signatureTypes
			.filter(search => search != type);
		if (checked) {
			signatureTypes.push(type);
		}
		this.props.onChangeSignatureTypes(signatureTypes);
	}

	onSignerChangeSignatureType = (id, type, checked) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					signatureTypes: (signer?.signatureTypes || [])
						.filter(search => search != type)
						.concat(checked ? [type] : [])
				}));

		this.props.onChangeMultiSigners(signers);
	}

	onSignerChangeCapacityTitle = (id, capacityTitle) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					capacityTitle
				}));

		this.props.onChangeMultiSigners(signers);
	}

	onSignerChangeUseKnownOtpNumber = (id, value) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					useKnownOtpNumber: value
				}));

		this.props.onChangeMultiSigners(signers);
	}

	onSignerChangeOtpNumber = (id, value) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.map(signer =>
				((signer.id !== id) ? signer : {
					...signer,
					useKnownOtpNumber: false,
					otpNumber: value
				}));

		this.props.onChangeMultiSigners(signers);
	}

	onChangeSelectedSignerIdForSettings = (selectedSignerIdForSettings) => {
		if (selectedSignerIdForSettings === this.state.selectedSignerIdForSettings) {
			this.setState({selectedSignerIdForSettings: ''})
		} else {
			this.setState({selectedSignerIdForSettings});
		}
	}

	onSignerDelete = (id) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.filter(signer => signer.id !== id);

		this.props.onChangeMultiSigners(signers);
	}

	onOpenSignerDialog = () => {
		this.setState({signerAddDialogOpen: true});
	}

	onCloseSignerDialog = () => {
		this.setState({signerAddDialogOpen: false});
	}

	onOpenSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: true});
	}

	onCloseSignerGroupDialog = () => {
		this.setState({signerGroupAddDialogOpen: false});
	}

	onOpenImportDialog = () => {
		this.setState({signerImportDialogOpen: true});
	}

	onCloseImportDialog = () => {
		this.setState({signerImportDialogOpen: false});
	}

	onSelectUsers = (usersToAdd, shouldClose) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.filter(signer => usersToAdd.findIndex(search => search.id === signer.id) === -1);

		usersToAdd.forEach(userToAdd => {
			signers.push({
				id: userToAdd.id,
				person: {
					id: userToAdd.id,
					firstName: userToAdd.firstName,
					lastName: userToAdd.lastName,
					email: userToAdd.email,
					capacityTitles: userToAdd.capacityTitles,
					metaFields: userToAdd.metaFields
				},
				otpNumberKnown: !!userToAdd.otpNumberSuggestionSuffix,
				useKnownOtpNumber: !!userToAdd.otpNumberSuggestionSuffix,
				otpNumberSuggestionSuffix: userToAdd.otpNumberSuggestionSuffix,
				signatureTypes: [...this.props.document.enabledSignatureTypes]
			})
		})

		if (shouldClose) {
			this.setState({signerAddDialogOpen: false, signerGroupAddDialogOpen: false, signerImportDialogOpen: false},
				() => this.props.onChangeMultiSigners(signers));
		} else {
			this.props.onChangeMultiSigners(signers);
		}
	}

	onOpenBulkSignatureTypesDialog = () => {
		this.setState({bulkSignatureTypesDialogOpen: true});
	}

	onCloseBulkSignatureTypesDialog = () => {
		this.setState({bulkSignatureTypesDialogOpen: false});
	}

	onBulkChangeSignatureTypes = (signatureTypes) => {
		const signers = EXTRACT_SIGNERS(this.props.document)
			.map(signer => ({
				...signer,
				signatureTypes: [...signatureTypes]
			}));

		this.setState({bulkSignatureTypesDialogOpen: false},
			() => this.props.onChangeMultiSigners(signers));
	}

	onOpenEmailMessageDialog = () => {
		this.setState({emailMessageDialogOpen: true});
	}

	onCloseEmailMessageDialog = () => {
		this.setState({emailMessageDialogOpen: false});
	}

	onUpdateEmailMessageDialog = (headers) => {
		this.props.onChangeSignersCustomEmailHeaders(headers);
		this.setState({emailMessageDialogOpen: false});
	}

}

export default withRouter(withTranslation()(connect(
	state => {
		return {
			sessionInfo: state.session.info,
			editorEditInfo: state.editor.editInfo,
		}
	},
	dispatch => {
		return {
			onDocumentSingleDownloadArtifacts: (id, types) => {
				dispatch({
					type: 'DOCUMENT_SINGLE_DOWNLOAD_ARTIFACTS',
					id,
					types
				});
			},
		}
	}
)(EditorMultiSignComponent)));
