import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {withTranslation} from "react-i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const defaultStateGen = (props) => ({
	userName: props.current?.userName || '',
	password: props.current?.password || '',
	domain: props.current?.domain || '',
	isDefault: props.current?.isDefault || false,
	showPassword: false
})

class CompanyEmailServerSettingsDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultStateGen(this.props)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultStateGen(this.props));
		}
	}

	componentWillUnmount() {
		this.props.onCompanyEmailServerConnectionValidateClear();
	}

	render() {
		return <>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="md"
			>
				<DialogTitle>{this.props.t('company.emailSettingsSendGridServerSettings')}</DialogTitle>
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('company.emailSettingsServerSettings')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', flexDirection: 'column', gap: 1, mt: 1}}>
									<TextField
										variant="outlined"
										type={this.state.showPassword ? 'text' : 'password'}
										label={this.props.t('company.emailSettingsServerPassword')}
										value={this.state.password || ''}
										onChange={this.onChangePassword}
										fullWidth
										autoComplete="new-password"
										id="input-company-email-server-password"
										InputProps={{
											endAdornment: <InputAdornment position="end">
												<IconButton
													onClick={this.onToggleShowPassword}
													edge="end">{this.state.showSecret ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										}}
									/>
								</Box>
								<Box sx={{mt: 2}}>
									<Button variant="contained" onClick={this.onValidateConnection}
											disabled={this.props.busy || !this.state.password}
											sx={{mr: 2}}
											id="btn-company-email-server-connection-test">
										{this.props.t('company.emailSettingsServerConnectionValidate')}
									</Button>

									{!!this.props.connectionResult &&
										<Alert sx={{mt: 2}} severity={this.props.connectionResult === 'VALID' ? "success" : "error"}>
											{this.props.t('company.emailSettingsServerConnectionValidate_' + this.props.connectionResult)}
										</Alert>
									}
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('company.emailSettingsServerApplicability')}</StepLabel>
							<StepContent>
								<Typography sx={{mb: 1}} variant="body2">{this.props.t('company.emailSettingsServerApplicabilityDescription')}</Typography>
								<FormControlLabel
									control={<Checkbox checked={this.state.isDefault}
													   onChange={this.onChangeDefault}/>}
									label={this.props.t('company.emailSettingsServerDefault')}
									sx={{mb: 1}}
								/>

								<TextField
									variant="outlined"
									label={this.props.t('company.emailSettingsServerDomains')}
									helperText={this.props.t('company.emailSettingsServerDomainsLabel')}
									value={this.state.domain || ''}
									onChange={this.onChangeDomain}
									fullWidth
									disabled={this.state.isDefault}
									id="input-company-email-server-domain"
								/>


							</StepContent>
						</Step>

					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-email-server-settings-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							onClick={this.onConfirm}
							disabled={!this.canContinue()}
							id="btn-email-server-settings-confirm"
					>
						{this.props.t('confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	}

	canContinue = () => {
		return ((!!this.state.isDefault || !!this.state.domain) && !!this.state.password);
	}

	onToggleShowPassword = (e) => {
		this.setState({showPassword: !this.state.showPassword})
	}

	onValidateConnection = () => {
		this.props.onCompanyEmailServerConnectionValidate(this.props.current?.id, this.state.password);
	}

	onChangeDomain = (e, value) => {
		this.setState({domain: e.target.value});
	}

	onChangePassword = (e) => {
		this.setState({password: e.target.value});
	}

	onChangeDefault = (e, isDefault) => {
		this.setState({isDefault,
		...(isDefault && {domain: null})});
	}

	onConfirm = () => {
		this.props.onConfirm({
			id: this.props.current?.id,
			internalId: this.props.current?.internalId,
			domain: this.state.domain,
			userName: this.state.userName,
			password: this.state.password,
			isDefault: this.state.isDefault,
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
			busy: state.company.busy,
			connectionResult: state.company.emailServerConnectionResult
		}
	},
	dispatch => {
		return {
			onCompanyEmailServerConnectionValidate: (emailServerSetingsId, apiKey) => {
				dispatch({
					type: 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE',
					emailServerSetingsId,
					apiKey
				});
			},
			onCompanyEmailServerConnectionValidateClear: () => {
				dispatch({
					type: 'COMPANY_EMAIL_SERVER_CONNECTION_VALIDATE_CLEAR'
				});
			}

		}
	}
)(CompanyEmailServerSettingsDialog));

