import React, {Component} from "react";
import {connect} from "react-redux";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {withTranslation} from "react-i18next";
import {IDENTIFICATION_ATTRIBUTE_TYPES} from "../common/Constants";

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

const defaultState = {
	description: '',
	file: null,
	fileName: '',
	password: ''
}

class CompanyWebhookSettingsConfigCreateDialog extends Component {

	constructor(props) {
		super(props);

		this.state = defaultState;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultState);
		}
	}

	render() {
		return <Dialog
			open={this.props.open}
			onClose={this.onClose}
			onKeyUp={this.onKeyUp}
			fullWidth
			maxWidth="sm"
		>
			<DialogTitle>{this.props.t('company.webhookSettingsConfigCreate')}</DialogTitle>
			<DialogContent>
				<Stepper activeStep={-1} orientation="vertical">
					<Step active>
						<StepLabel>{this.props.t('company.webhookSettingsConfigDescription')}</StepLabel>
						<StepContent>
							<TextField
								variant="outlined"
								label={this.props.t('company.webhookSettingsConfigDescription')}
								value={this.state.firstName}
								onChange={this.onChangeDescription}
								autoComplete="off"
								fullWidth
								autoFocus
								required
							/>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.webhookSettingsConfigSelectTitle')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.webhookSettingsConfigSelectDescription')}</Typography>
							<Box sx={{display: 'flex', gap: 1, alignItems: 'center', mt: 1}}>
								<Button
									component="label"
									role={undefined}
									variant="contained"
									size="small"
								>
									{this.props.t('company.webhookSettingsConfigSelect')}
									<VisuallyHiddenInput
										type="file"
										onChange={this.onChangeFiles}
									/>
								</Button>
								<Typography variant="body2">{this.state.fileName}</Typography>
							</Box>
						</StepContent>
					</Step>
					<Step active>
						<StepLabel>{this.props.t('company.webhookSettingsConfigFilePassword')}</StepLabel>
						<StepContent>
							<Typography>{this.props.t('company.webhookSettingsConfigFilePasswordDescription')}</Typography>
							<TextField
								variant="outlined"
								type="password"
								label={this.props.t('company.webhookSettingsConfigFilePassword')}
								value={this.state.password}
								onChange={this.onChangePassword}
								autoComplete="new-password"
								fullWidth
								sx={{mt: 1}}
							/>
						</StepContent>
					</Step>
				</Stepper>
			</DialogContent>
			<DialogActions>
				<Button onClick={this.onClose} id="btn-create-cancel">{this.props.t('cancel')}</Button>
				<Button variant="contained" disabled={!this.canContinue()} onClick={this.onCreate}
						id="btn-create-confirm">
					{this.props.t('company.webhookSettingsConfigCreate')}
				</Button>
			</DialogActions>
		</Dialog>
	}

	onChangeDescription = (e) => {
		this.setState({description: e.target.value});
	}

	onChangeFiles = (e) => {
		const file = e.target.files?.[0];
		if (!!file) {
			const reader = new FileReader();
			reader.onload = () => {
				const result = reader.result;
				if (!!result && result.startsWith('data:application/x-pkcs12;base64,')) {
					this.setState({file: result.substring(33), fileName: file.name});
				} else {
					this.setState({file: null, fileName: ''});
				}
			};
			reader.onerror = () => this.setState({file: null, fileName: ''});
			reader.readAsDataURL(file);
		} else {
			this.setState({file: null, fileName: ''});
		}
	}

	onChangePassword = (e) => {
		this.setState({password: e.target.value});
	}

	canContinue = () => {
		return !!this.state.description && !!this.state.file;
	}

	onKeyUp = (e) => {
		if (e.key === 'Enter' && this.canContinue()) {
			this.onCreate();
		}
	}

	onCreate = () => {
		this.props.onCreate({
			description: this.state.description,
			pkcs12FileBase64: this.state.file,
			pkcs12Password: this.state.password
		});
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {
		}
	},
	dispatch => {
		return {
		}
	}
)(CompanyWebhookSettingsConfigCreateDialog));
