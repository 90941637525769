import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import {DataGridPro as DataGrid} from "@mui/x-data-grid-pro";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from "@mui/icons-material/Delete";
import SelectUserDialog from "../common/SelectUserDialog";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DelegateFileUploadDialog from "./DelegateFileUploadDialog";
import PendingChangesDialog from "../common/PendingChangesDialog";

const defaultStateGen = (props) => ({
	delegateList: props.delegateList || {type: 'DOCUMENT_APPROVAL', forPerson: null, delegates: []},
	forPersonDialogOpen: false,
	addDelegatesDialogOpen: false,
	editDelegateId: null,
	pendingChangesDialogOpen: false,
})

class DelegateListAddCreateDialog extends Component {

	constructor(props) {
		super(props);
		this.state = defaultStateGen(this.props)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.open && !prevProps.open) {
			this.setState(defaultStateGen(this.props));
		} else if (!!this.props.delegateList && this.props.delegateList !== prevProps.delegateList) {
			this.setState({delegateList: this.props.delegateList});
		}
	}

	render() {
		const dataGridColumns = [
			{
				field: 'order',
				headerName: this.props.t('delegates.order'),
				editable: false,
				width: 100,
				renderCell: (cellValues) => <Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.up')}
						onClick={() => this.onDelegateUp(cellValues.row.id)}>
						<ArrowUpwardIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.down')}
						onClick={() => this.onDelegateDown(cellValues.row.id)}>
						<ArrowDownwardIcon/>
					</IconButton>
				</Fragment>
			},
			{
				field: 'firstName',
				headerName: this.props.t('delegates.firstName'),
				editable: false,
				flex: 1,
			},
			{
				field: 'lastName',
				headerName: this.props.t('delegates.lastName'),
				editable: false,
				flex: 1,
			},
			{
				field: 'email',
				headerName: this.props.t('delegates.email'),
				editable: false,
				flex: 1,
			},
			{
				field: 'fileName',
				headerName: this.props.t('delegates.fileName'),
				editable: false,
				flex: 1,
			},
			{
				field: 'delete',
				headerName: '',
				editable: false,
				width: 100,
				renderCell: (cellValues) => <Fragment>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.uploadFile')}
						onClick={() => this.onDelegateEditDialogOpen(cellValues.row.id)}>
						<EditIcon/>
					</IconButton>
					<IconButton
						variant="contained"
						color="primary"
						title={this.props.t('delegates.delete')}
						onClick={() => this.onDelegateDelete(cellValues.row.id)}>
						<DeleteIcon/>
					</IconButton>
				</Fragment>
			},
		]


		return <Fragment>
			<Dialog open={this.props.open}
					onClose={this.onClose}
					fullWidth
					maxWidth="lg"
			>
				<DialogTitle>{!this.props.delegateList ? this.props.t('delegates.create') : this.props.t('delegates.edit')}</DialogTitle>
				<DialogContent>
					<Stepper activeStep={-1} orientation="vertical">
						<Step active>
							<StepLabel>{this.props.t('delegates.type')}</StepLabel>
							<StepContent>
								<FormControl fullWidth>
									<Select
										value={this.state.delegateList.type}
										onChange={this.onChangeType}
										disabled={!!this.props.delegateList}
									>
										{['DOCUMENT_APPROVAL', 'DOCUMENT_SIGNATURE'].map(type =>
											<MenuItem key={type}
													  value={type}>{this.props.t('delegates.type_' + type)}
											</MenuItem>)}
									</Select>
								</FormControl>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('delegates.for')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', alignItems: 'center'}}>
									<TextField
										variant="outlined"
										required
										value={!this.state.delegateList.forPerson ? this.props.t('delegates.forEveryone') : (
											this.state.delegateList.forPerson.firstName + ' ' +
											this.state.delegateList.forPerson.lastName + ' (' +
											this.state.delegateList.forPerson.email + ')'
										)}
										onChange={this.onChangeName}
										disabled
										sx={{width: 500, mr: 2}}
									/>
									{!this.props.delegateList && <Fragment>
										{!!this.state.delegateList.forPerson &&
											<Button
												variant="contained"
												color="secondary"
												sx={{ mr: 2 }}
												onClick={this.onDeleteForPerson}
												id="btn-delegate-list-specific-user-delete"
											>
												{this.props.t('delegates.deleteFor')}
											</Button>}
										<Button
											variant="contained"
											color="secondary"
											onClick={this.onOpenForPersonDialog}
											id="btn-delegate-list-specific-user-add"
										>
											{this.props.t('delegates.chooseFor')}
										</Button>
									</Fragment>}
								</Box>
							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('delegates.delegates')}</StepLabel>
							<StepContent>
								<Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
									<Button variant="contained"
											onClick={this.onOpenAddDelegatesDialog}
											startIcon={<AddIcon/>}
											id="btn-delegate-list-add-user"
									>
										{this.props.t('delegates.addDelegates')}
									</Button>
								</Box>
								<DataGrid
									autoHeight
									disableColumnSelector
									disableColumnFilter
									disableRowSelectionOnClick
									pagination

									initialState={{
										pagination: {
											paginationModel: { pageSize: 10, page: 0 },
										},
									}}

									columns={dataGridColumns}
									rows={this.state.delegateList.delegates}
									pageSizeOptions={[10]}
									density="compact"
								/>

							</StepContent>
						</Step>

						<Step active>
							<StepLabel>{this.props.t('folder.settingsFinish')}</StepLabel>
							<StepContent>
								<Typography>{this.props.t('folder.settingsFinishDescription')}</Typography>
							</StepContent>
						</Step>
					</Stepper>
				</DialogContent>

				<DialogActions>
					<Button onClick={this.onClose} id="btn-delegate-list-create-cancel">{this.props.t('cancel')}</Button>
					<Button variant="contained"
							onClick={this.onUpdate}
							id="btn-delegate-list-create-confirm"
					>
						{this.props.t('folder.settingsSave')}
					</Button>
				</DialogActions>
			</Dialog>

			<SelectUserDialog
				title={this.props.t('delegates.chooseFor')}
				users={this.props.relevantUsers}
				open={this.state.forPersonDialogOpen}
				onClose={this.onCloseForPersonDialog}
				onSelect={this.onSelectForPerson}
			/>

			<SelectUserDialog
				multiselect
				title={this.props.t('delegates.addDelegates')}
				users={this.props.relevantUsers}
				open={this.state.addDelegatesDialogOpen}
				onClose={this.onCloseAddDelegatesDialog}
				onSelect={this.onSelectAddDelegates}
			/>

			<DelegateFileUploadDialog
				open={!!this.state.editDelegateId}
				onUploadSuccess={this.onRefreshDelegateList}
				onDeleteSuccess={this.onRefreshDelegateList}
				onDownloadFile={this.props.onDownloadDelegateFile}
				onDeleteFile={this.props.onDeleteDelegateFile}
				onClose={this.onCloseEditDelegateDialog}
				delegateListId={this.props.delegateList?.id}
				delegate={this.props.delegateList?.delegates.length > 0 && this.props.delegateList.delegates.find(del => del.id === this.state.editDelegateId)}
				getUploadFileUrl={this.props.getUploadFileUrl}
			/>

			<PendingChangesDialog
				open={this.state.pendingChangesDialogOpen}
				onClose={this.onPendingChangesCloseDialog}
				busy={this.props.companyBusy}
				onConfirm={this.onPendingChangesSaveConfirm}
				labelContent={this.props.t('delegates.fileSaveFirst')}
				labelConfirm={this.props.t('confirm')}
			/>
		</Fragment>
	}

	onDelegateUp = (id) => {
		const delegates = [...this.state.delegateList.delegates];
		for (let i = 0; i < delegates.length; i++) {
			if (delegates[i]?.id === id) {
				if (i >= 1) {
					const tmp = delegates[i];
					delegates[i] = delegates[i - 1];
					delegates[i - 1] = tmp;
				}
				break;
			}
		}
		this.setState({
			delegateList: {
				...this.state.delegateList,
				delegates
			}
		});
	}

	onDelegateDown = (id) => {
		const delegates = [...this.state.delegateList.delegates];
		for (let i = 0; i < delegates.length - 1; i++) {
			if (delegates[i]?.id === id) {
				const tmp = delegates[i];
				delegates[i] = delegates[i + 1];
				delegates[i + 1] = tmp;
				break;
			}
		}
		this.setState({
			delegateList: {
				...this.state.delegateList,
				delegates
			}
		});
	}

	onDelegateEditDialogOpen = (id) => {
		if (!this.props.delegateList || this.props.delegateList.delegates.find(del => del.id === id) === undefined) {
			// the delegate was not yet persisted, we'll need to save first before we can allow a file upload
			this.setState({pendingChangesDialogOpen: true});
		} else {
			this.setState({editDelegateId: id});
		}
	}

	onPendingChangesCloseDialog = () => {
		this.setState({pendingChangesDialogOpen: false})
	}

	onPendingChangesSaveConfirm = () => {
		this.setState({pendingChangesDialogOpen: false}, () => this.props.onSave(this.state.delegateList, false));
	}

	onCloseEditDelegateDialog = () => {
		this.setState({
			editDelegateId: null
		})
	}

	onRefreshDelegateList = () => {
		this.props.onRefresh();
	}

	onDelegateDelete = (id) => {
		const delegates = this.state.delegateList.delegates
			.filter(delegate => delegate?.id !== id);
		this.setState({
			delegateList: {
				...this.state.delegateList,
				delegates
			}
		});
	}

	onChangeType = (e, value) => {
		this.setState({
			delegateList: {
				...this.state.delegateList,
				type: e.target.value
			}
		});
	}

	onDeleteForPerson = () => {
		this.setState({
			delegateList: {
				...this.state.delegateList,
				forPerson: null
			}
		});
	}

	onOpenForPersonDialog = () => {
		this.setState({
			forPersonDialogOpen: true
		})
	}

	onCloseForPersonDialog = () => {
		this.setState({
			forPersonDialogOpen: false
		})
	}

	onSelectForPerson = (values) => {
		const forPerson = this.props.relevantUsers
			.filter(user => user.id === values[0])
			.at(0);
		this.setState({
			delegateList: {
				...this.state.delegateList,
				forPerson
			},
			forPersonDialogOpen: false
		});
	}

	onOpenAddDelegatesDialog = () => {
		this.setState({
			addDelegatesDialogOpen: true
		})
	}

	onCloseAddDelegatesDialog = () => {
		this.setState({
			addDelegatesDialogOpen: false
		})
	}

	onSelectAddDelegates = (values) => {
		const delegatesToAdd = this.props.relevantUsers
			.filter(delegate => values.indexOf(delegate.id) >= 0 && this.state.delegateList.delegates.find(del => del.id === delegate.id) === undefined)
		const delegates = this.state.delegateList.delegates.concat(delegatesToAdd);

		this.setState({
			delegateList: {
				...this.state.delegateList,
				delegates
			},
			addDelegatesDialogOpen: false,
		});
	}

	onUpdate = () => {
		this.props.onSave(this.state.delegateList, true);
	}

	onClose = (e, reason) => {
		if (reason !== 'backdropClick') {
			this.props.onClose();
		}
	}
}

export default withTranslation()(connect(
	state => {
		return {}
	},
	dispatch => {
		return {}
	}
)(DelegateListAddCreateDialog));
