export const GENERATE_USER_IMPORT_TEMPLATE_DATA = () => {
	const lines = [];
	lines.push("email,firstName,lastName,memo,phoneNumber,defaultRemarkVisualSignature,language,guest,allowedToBecomeRegistered,contact,contactFolderName,enabledNotifications,userGroupNames");
	lines.push("#mandatory,mandatory for user creation,mandatory for user creation,optional,optional,optional,optional; values: ENGLISH|FRENCH|DUTCH; default: ENGLISH,optional; values:Y|N; default:Y,optional; values:Y|N; default:N; relevant for guests only,optional; values: Y|N; default: N,mandatory for contact creation,optional; comma separated list; possible values:INVITE|REMINDER|APPROVAL|FULLY_SIGNED|SIGNATURE_PLACED|CLOSED|EXPIRED ;by default all notifications are enabled if this column is omitted; empty field disables all notifications,optional; comma separated list with existing user group names; users will always be added to the default company group regardless of these values;not relevant for guest users");
	lines.push("john.doe@dioss.com,John,Doe,Insert memo here,+324711223344,Insert remark here,ENGLISH,Y,Y,N,,\"REMINDER\",");
	lines.push("jan.janssen@dioss.com,Jan,Janssen,,,Insert remark here,DUTCH,Y,N,N,,\"APPROVAL,FULLY_SIGNED\",");
	lines.push("jean.pinot@dioss.com,Jean,Pinot,Insert memo here,,,FRENCH,N,N,Y,HR,\"INVITE,REMINDER,APPROVAL,FULLY_SIGNED,SIGNATURE_PLACED,CLOSED,EXPIRED\",\"Accounting, Billing\"");
	lines.push("jerry.bean@dioss.com,Jerry,Bean,,,,ENGLISH,N,N,N,,,\"Marketing\"");
	return lines.join('\n');
}

// csv example without a contact folder name input option
export const GENERATE_USER_IMPORT_WITH_FIXED_CONTACTS_FOLDER_DATA = () => {
	const lines = [];
	lines.push("email,firstName,lastName,memo,phoneNumber,defaultRemarkVisualSignature,language,guest,allowedToBecomeRegistered,contact,enabledNotifications,userGroupNames");
	lines.push("#mandatory,mandatory for user creation,mandatory for user creation,optional,optional,optional,optional; values: ENGLISH|FRENCH|DUTCH; default: ENGLISH,optional; values:Y|N; default:Y,optional; values:Y|N; default:N; relevant for guests only,optional; values: Y|N; default: N,optional; comma separated list; possible values:INVITE|REMINDER|APPROVAL|FULLY_SIGNED|SIGNATURE_PLACED|CLOSED|EXPIRED ;by default all notifications are enabled if this column is omitted; empty field disables all notifications,optional; comma separated list with existing user group names; users will always be added to the default company group regardless of these values;not relevant for guest users");
	lines.push("john.doe@dioss.com,John,Doe,Insert memo here,+324711223344,Insert remark here,ENGLISH,Y,Y,N,\"REMINDER\",");
	lines.push("jan.janssen@dioss.com,Jan,Janssen,,,Insert remark here,DUTCH,Y,N,N,\"APPROVAL,FULLY_SIGNED\",");
	lines.push("jean.pinot@dioss.com,Jean,Pinot,Insert memo here,,,FRENCH,N,N,N\"INVITE,REMINDER,APPROVAL,FULLY_SIGNED,SIGNATURE_PLACED,CLOSED,EXPIRED\",\"Accounting, Billing\"");
	lines.push("jerry.bean@dioss.com,Jerry,Bean,,,,ENGLISH,N,N,N,\"Marketing\"");
	return lines.join('\n');
}


