import React, {Component} from "react";
import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import {withTranslation} from "react-i18next";
import ConnectorActivityGridComponent from "./ConnectorActivityGridComponent";
import SaveIcon from "@mui/icons-material/Save";

class SftpConnectorComponent extends Component {

	constructor(props) {
		super(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (!prevProps.shouldSave && this.props.shouldSave) {
			this.onSave();
		}
	}

	render() {
		const config = {};
		this.props.instance.config.forEach(entry => config[entry.key] = entry.value);

		const activityConfigOptions = [{
			name: 'directory',
			label: this.props.t('connector.directory'),
			description: (action) => this.props.t('connector.sftpDirectory_' + action),
			required: true
		}];

		return <Box sx={{mt: 1}}>
			<Box sx={{mb: 2, mt: 2}}>
				<Typography sx={{fontWeight: 700}}>{this.props.t('connector.sftpConfig')}</Typography>

				<Box sx={{mt: 2}}>
					<TextField
						variant="outlined"
						value={config.host || ''}
						label={this.props.t('connector.host')}
						onChange={this.onChangeHost}
						autoComplete="off"
						type="text"
						sx={{width: 300}}
						disabled={this.props.busy}
						required={true}
						error={!config.host || config.host.trim().length < 1}
					/>
					<TextField
						value={config.port || ''}
						label={this.props.t('connector.port')}
						onChange={this.onChangePort}
						autoComplete="off"
						type="number"
						sx={{
							width: 300, ml: 2,
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
						disabled={this.props.busy}
						required={true}
						error={!config.port || config.port.trim().length < 1}
					/>
				</Box>

				<Box sx={{mt: 2, mb: 1}}>
					<TextField
						variant="outlined"
						value={config.user || ''}
						label={this.props.t('connector.user')}
						onChange={this.onChangeUser}
						autoComplete="off"
						type="text"
						sx={{width: 300}}
						disabled={this.props.busy}
						required={true}
						error={!config.user || config.user.trim().length < 1}
					/>
					<TextField
						variant="outlined"
						value={config.password || ''}
						label={this.props.t('connector.password')}
						onChange={this.onChangePassword}
						autoComplete="new-password"
						type="password"
						sx={{width: 300, ml: 2}}
						disabled={this.props.busy}
						required={true}
						error={!config.password || config.password.trim().length < 1}
					/>
				</Box>

				<Box sx={{mt: 2}}>
					<Button variant="contained" onClick={this.onTest}
							disabled={this.props.busy || !this.props.hasValidConfig}
							sx={{mr: 2}}
							id="btn-settings-test">
						{this.props.t('connector.testConnection')}
					</Button>
					<Button variant="contained" onClick={this.onSave} disabled={this.props.busy} startIcon={<SaveIcon/>}
							id="btn-settings-save">
						{this.props.t('save')}
					</Button>
				</Box>

				{!!this.props.testResult && <Box sx={{mt: 2}}>
					<Alert severity={this.props.testResult.success ? "success" : "error"}>
						{this.props.testResult.success ? this.props.t('connector.test_SUCCESS') : this.props.t('connector.test_' + this.props.testResult.error)}
					</Alert>
				</Box>}

			</Box>

			<ConnectorActivityGridComponent instanceId={this.props.instance.id}
											availableActions={this.props.instance.actions}
											activityConfigOptions={activityConfigOptions}
											busy={this.props.busy}/>

		</Box>
	}

	onChangeHost = (e) => {
		this.props.onChangeConnectorInstanceConfig(this.props.instance.id, 'host', e.target.value);
	}

	onChangePort = (e) => {
		this.props.onChangeConnectorInstanceConfig(this.props.instance.id, 'port', e.target.value);
	}

	onChangeUser = (e) => {
		this.props.onChangeConnectorInstanceConfig(this.props.instance.id, 'user', e.target.value);
	}

	onChangePassword = (e) => {
		this.props.onChangeConnectorInstanceConfig(this.props.instance.id, 'password', e.target.value);
	}

	onSave = () => {
		this.props.onUpdateConnectorInstanceConfig(this.props.instance);
	}

	onTest = () => {
		this.props.onTest(this.props.instance);
	}

}

export default withTranslation()(SftpConnectorComponent);