const defaultState = {
	open: false
}

const snackbar = (state = defaultState, action) => {
	switch (action.type) {

		case 'SNACKBAR_OPEN':
			return {
				open: true,
				message: action.message,
				messageKey: action.messageKey,
				messageSeverity: action.messageSeverity,
			}
		case 'SNACKBAR_CLOSE':
			return {
				...state,
				open: false
			};
		default:
			return state;
	}
}

export default snackbar;
